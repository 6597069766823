import { Icon, IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faGlasses, faLink, faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink as IconDefinition);
  library.add(faUser as IconDefinition);
  library.add(faPowerOff as IconDefinition);
  library.add(faGlasses as IconDefinition);
  library.add(faEye as IconDefinition);
}

export default initFontAwesome;
