// ScoreModal.tsx
import React, { useEffect, useState } from 'react';
import { useStore } from '../../stores/store';
import ScoreWidget from './ScoreWidget';
import { FocusModeSwitchResponse } from '../../models/foucsModeResponse';
import { Score } from '../../models/score';
import ProgressBar from '../ProgressBar';
import { ScoreAccumulator } from '../../views/Performance';
import { observer } from 'mobx-react-lite';
import Gauge from '../Gauge';



interface AggregatedScore {
    name: string;
    score: number; // Assuming this is already a percentage
    label?: string;
    url?: string;
  }

  
interface ScoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  switchResponse: FocusModeSwitchResponse;
  date: string;
}

const ScoreModal: React.FC<ScoreModalProps> = ({ isOpen, switchResponse, onClose, date }) => {
  const { scoreStore } = useStore(); // Accessing the store

  const [selectedReflections, setSelectedReflections] = useState<string[]>([]);

  useEffect(() => {
  
      const setToken = async () => {
        await scoreStore.listReflections();
      }

      setToken();
    
  }, [scoreStore]); // Add location.pathname to the dependency array
  
  if (!isOpen) {
    return null;
  }

  const addReflection = (id: string) => {
    setSelectedReflections(prev => {
      if (prev.includes(id)) {
        return prev.filter(reflectionId => reflectionId !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  
  const createReflection = async (name: string) => {
    var reflection = await scoreStore.createReflectionTag(name);
    addReflection(reflection?.id!);
  };
  
  
  const handleSubmitReflections = () => {
    selectedReflections.forEach(id => scoreStore.addReflection(id));
    setSelectedReflections([]); // Clear the selections after submission
    onClose(); // Optionally close the modal or provide feedback
  };

  const calculateTimeSince = (timestamp: string) => {
    // Parse the timestamp into a Date object
    const pastDate = new Date(timestamp);
    const currentDate = new Date();
  
    // Calculate the difference in milliseconds
    const differenceInMs = currentDate.getTime() - pastDate.getTime();
  
    // Convert milliseconds to hours and minutes
    const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);
  
    return { hours, minutes };
  };
  
  // Usage example
  const timestamp = date;
  const { hours, minutes } = calculateTimeSince(timestamp);
  

const displayedScores = scoreStore.scores
.flatMap(group => group.scores as Score[])
.filter(score => score.parentScoreType == null)
.reduce((acc: ScoreAccumulator, score) => {
  if (!acc[score.scoreType]) {
    acc[score.scoreType] = { total: score.score, count: 1, name: score.name, label: score.label, url: score.url };
  } else {
    acc[score.scoreType].total += score.score;
    acc[score.scoreType].count += 1;
  }
  return acc;
}, {});

const aggregatedScores: AggregatedScore[] = Object.values(displayedScores).map(({ total, count, name, label, url }) => ({
name,
score: Math.min(100, (total / count) * 100), // Cap the score at 100
label,
url
}));
  

// Now, use displayedScores in the render method to display progress bars
const totalAverageScore = aggregatedScores.reduce((acc, { score }) => acc + score, 0) / aggregatedScores.length;

  return (
    <div className={`modal `} style={{  overflowY: 'auto', display: 'block' }}>
  <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{switchResponse.summary!.title}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-4">
        {switchResponse.details.tasksClosed} tasks closed - {switchResponse.details.engagedUserIds} engagements (Width of network)
        <div style={{textAlign: "center"}} className='mt-4'>
            <h4>{totalAverageScore.toFixed(0)}<small className="ml-2">Good</small></h4>
            <Gauge value={totalAverageScore!} ></Gauge>
        </div>
        <br/>
        <div className="mt-4">
        <b>Was anything impacting your productivity?: </b>
        
            <span className={`badge border border-secondary mr-2`} key={'tags-create'} onClick={() => createReflection(prompt('Name?')!)}>+ Add a tag</span>
        {scoreStore.reflections.map((x, i) => {
            var css = selectedReflections.includes(x.id) ? 'badge-dark' : 'badge-light';
            return <span className={`badge ${css} mr-2`} key={i} onClick={() => addReflection(x.id)}>{x.name}</span>
          })}
        </div>
        <button onClick={handleSubmitReflections} className="btn btn-primary mt-3 btn-sm" disabled={selectedReflections.length === 0}>Add reflections</button>
        <hr></hr>
        <p className="mt-3">{switchResponse.summary!.summary}</p>
        {switchResponse.summary!.recommendations.length > 0 && (
          <>
            <b>Recommendations</b>
            <ul>
              {switchResponse.summary!.recommendations.map((r, i) => <li key={i}>{r}</li>)}
            </ul>
          </>
        )}
        {/* Render information from scoreStore */}
        {aggregatedScores.length > 0 ? <h4 className='mt-4'>Productivity contributors</h4> : ""}
        {aggregatedScores.length > 0 ? (
            
          aggregatedScores.map((score, i) => {
            return (<>
            <div className="card mb-2 mt-4" key={i}>   
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <small>{score.name}</small>
                  <small>{score.label}</small>
                </div>
                <ProgressBar value={score.score} />
              </div>
            </div></>
          )})
        ) : (<p>No scores available.</p>)}
      </div>
    </div>
  </div>

  <div className="modal-backdrop show"></div>
</div>

  );
};

export default observer(ScoreModal);
