import React, { useEffect } from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import { useParams } from "react-router-dom";


const AppsComponent = () => {
  const { getIdTokenClaims } = useAuth0();
  const  { integrationStore, workflowStore } = useStore();

  useEffect(() => {
    const fetch = async () => {
      try {
        await integrationStore.loadIntegrations();
      
        console.log("load")
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetch(); 

  }, []);

  // React component for Slack authentication

  const redirectUri = process.env.REACT_APP_INTEGRATION_CALLBACK;


  const { companyId } = useParams<{ companyId: string }>();
  
  
  const handleSlackConnect = () => {
    const clientId = "1813727230180.6504203344452";
    const state = "slack";
    window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=channels:read,chat:write.public&redirect_uri=${redirectUri}&state=${state}`;
  };

  const handleTodoistConnect = () => {
    const clientId = "e4009f41c1d04b60908eb12825ebd0d4";
    const _redirectUri = `${redirectUri}?app=todoist`;
    const state = "todoist";
    window.location.href = `https://todoist.com/oauth/authorize?client_id=${clientId}&scope=data:read,task:add&redirect_uri=${_redirectUri}&state=${state}`;
  };


  const handleOuraConnect = () => {
    const clientId = "6BN4KP65BDQBPZ3H";
    const _redirectUri = `${redirectUri}`;
   // const state = "oura";
    const stateObject = {
      app: "oura",
      companyid: companyId
    };
    const stateParameter = encodeURIComponent(JSON.stringify(stateObject));
    
    window.location.href = `https://cloud.ouraring.com/oauth/authorize?client_id=${clientId}&redirect_uri=${_redirectUri}&state=${stateParameter}&response_type=code`;
  };

  const handleDisconnect = async (id: string) => {
    await integrationStore.delete(id);
    await integrationStore.loadIntegrations();
  };


  if(integrationStore.integrations == null)
   return <>loading....</>


  var slack =  integrationStore.integrations?.some((x) => x.type === "slack" );
  var todoist = integrationStore.integrations?.some((x) => x.type === "todoist" );
  var oura = integrationStore.integrations?.some((x) => x.type === "oura" );

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
    
        <Col md>
          <h2>Apps</h2>
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <img width={24} src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png"></img>  Slack
              </div>
              <div className="col-sm">
                {slack && <button onClick={() => handleDisconnect(integrationStore.integrations?.find((x) => x.type === "slack" )!.id!)} className="btn btn-info">Disconnect</button>}
                {!slack && <button onClick={handleSlackConnect} className="btn btn-success">Connect </button>}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm">
                <img width={24} src="https://static-00.iconduck.com/assets.00/todoist-icon-512x512-v3a6dxo9.png"></img>  Todoist
              </div>
              <div className="col-sm">
                {todoist && <button onClick={() => handleDisconnect(integrationStore.integrations?.find((x) => x.type === "todoist" )!.id!)} className="btn btn-info">Disconnect</button>}
                {!todoist && <button onClick={handleTodoistConnect} className="btn btn-success">Connect </button>}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm">
                <img width={24} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAAZlBMVEUvSnP///87U3nk5uqjq7oKNWcsSHIoRnEjQm4AMWQeP2xldZEXO2rs7vHz9PYxTHQALGLZ3OLP09uutcIAI12YorN5hp7Gy9QAGlm5v8pSZYZfcI1GW39ufZeFkKWSnK8AFFcADlWKdpmAAAAE9ElEQVR4nO2b23KrOgyGCcQHjMEcApRjut//JTfpsg1N04JZbSPW6LvpRYcZ/ZEsyUJ4HoIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIL8OpSt8WwLt0P9VZ5t4mYozYMV8o4/28qNUBqcVoijw4hh62KO4xkUA5R/SwyN18Qkh0kAHr2eVyj7w5RNKskK8jBaJtQKz7YPQcAyXQa4hjH6bGv+AsqEUH3bdl0URV3X9j4X/JiCmOTtMJ7rINF1MsiL69BSeZhyaeGkH4s8+VD5s2JsiXi2dU6wtD3XH5RoPXkRpQfyTuifs686s6BpU3qMwsnJ9Uspb3LOXD7bzi2Qvl6TciPrQvCJjZJhtf/XXAnwbpPx8r3FSVCMUe+rvhvP2V1KaBToPMC8YikkbgZyCaWYaj8XklzSrgiWgmpfwE0DjDYLKXmpUnG7Dej/Tn9FKsZlys7gqmF84Zf86j06E1TKcZEgcqhqqJy1JEUfPq4kihJazgOPGui5Ceezn1VfZKpJTjQ7p5AQMzSpbE6u2xULRT87sQx/yUAHeJ9bLf1q6CzSXhBJcMeGWOtqf8MxWCSLWkErntIGWb5Fy+26Y89NSX7aOjeYbypM0G3sIDk1cZm1sDKaqExOvqZbn5GRcWYJSszsmNohN6Vn401QruGRueivJ7IZKk3xHEENbsxvfN4cZBNKDjZpwBHDfBP9juU8Na4BFGfM/MKFi2O8W0KHlwJsAXSNFir1WcvgTJ+EjpbAufpJkwXhjDeYCRbn35d1+tEBimuEOTKtc5NFfZMGCYxuUxGdmBP3BEuV7mlyx9TxU6hQB36mdlQLnTvil+83bBcXPcBsPHfPsKuOs9fvt2sXL7pknnc4hptKA8UzL7pYlHvERFoMkDPjvZrMTN3VWDFQJgEv2p7rDs8IIwbKbdOG2Y67vG3rLjDqjHfR3Uyx41k2ajH/fbtZ+7jowlfvqDPU3ISAZDNbNAN3MdQzz15+wrQdSBMqe9oZHaJNCOTMsNa0vs5XLNabTAhlrEm5tqhx7uO5cap7w/1TCPN22bnyEf1kIsBMNOyLTNcrFqX6wRrODMAemuzVKfJVaBJzBSbKpvuiGRu7zAAnLvqxxAckxp7jzGkIGJqpTrHnWvdT8NZM86rtDaMSJi+fQG05UzufDXy+9dgwYYKzhrXlLCLjmmbrV0tUlmbaXkGZM2mYcU1SbpznzS90G0DH/w3emsIZV1um50paX8ZgBoAWOSbWuHU1SlrxpwKclukI2BeuyUDW1Eib/k6ZByzIbrDFVzPjymIcae02UNzCc8xb2ZjX5hr5+QIz5XJeTElGKGOZO8gwb18lkRIPw4cKr12scJZQBhkfSMfFLlk9+B/3y7lQ3XK/rgBy9X9EOi4XNJuxY2QSRG+f03i3ZUDejsVCb+L62vB3kdW7b+eyoqxaX4YT0muH6/sd9Bjansk9IspP74izunmjzu++EcwqONfLT+B98cne/B11d4AvNphXrX6meUvJQPcZ76CMlWtaGsUB1v2HUBkWX0nJ2Wq/AwkahmXw8OwkccEvB/lEw6A8knZFFsRLRUmQNQMJjxJgC5THw7Svyib/Q12fx5akwjuWVyxKMUmmekmVoiINydR9HlSJ5q2ToexPS4MgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIcjv8BiAk71c6R8poAAAAASUVORK5CYII="></img>  Oura
              </div>
              <div className="col-sm">
                {oura && <button onClick={() => handleDisconnect(integrationStore.integrations?.find((x) => x.type === "oura" )!.id!)} className="btn btn-info">Disconnect</button>}
                {!oura && <button onClick={handleOuraConnect} className="btn btn-success">Connect </button>}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(observer(AppsComponent), {
  onRedirecting: () => <Loading />,
});
