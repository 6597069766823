import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Task, TaskStatus, TaskPriority, TaskCategory } from '../models/task';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingIndicator from '../components/LoadingIndicator';

interface TasksComponentState {
  showResult: boolean;
  apiMessage: string;
  error: Error | null;
}

type GroupedTasks = {
  [key: string]: Task[];
};

type SortOrder = 'asc' | 'desc';

const RedirectorComponent = () => {
  const { threadId } = useParams<{ threadId?: string }>();
  const { taskStore, threadStore } = useStore();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your API call
        await taskStore.getThreadFromThreadId(threadId!);
        if(taskStore.redirThread)
            navigate(`/threads/${taskStore.redirThread.id}`);
      } catch (error) {
        console.error('Error during redirection', error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, [threadId, navigate, taskStore.redirThread]);

  if(taskStore.loadingInitial)
    return <div>Redirecting...</div>;


    return <div>Not found</div>;
};

export default withAuthenticationRequired(observer(RedirectorComponent), {
  onRedirecting: () => <LoadingIndicator />,
});
