
import React, { useEffect } from "react";
import {  Route, Routes, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Profile from "./views/Profile";
import {  useAuth0 } from "@auth0/auth0-react";
import CustomRouter from "./CustomRouter"

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { store, useStore } from "./stores/store";
import { observer } from "mobx-react-lite";
import { createBrowserHistory } from "history";
import Tasks from "./views/Tasks";
import Login from "./Login";
import Redirector from "./views/Redirector";
import WorkflowBuilder from "./views/Flows";
import Onboarding from "./views/Onboarding";
import { PluginsComponent } from "./views/Plugins";
import Callback from "./views/Callback";
import Apps from "./views/Apps";
import Activity from "./views/Logs";
import Performance from "./views/Performance";
import AlertModal from "./components/AlertModal";
import Start from "./views/Start";
import Triage from "./Triage";
import CompanyUsers from "./views/CompanyUsers";
import ScoreWidget from "./components/Scores/ScoreWidget";
import Footer from "./components/Footer";
import Dashboard from "./views/Dashboard";
import CallbackProcess from "./views/CallbackProcess";

initFontAwesome();

const App = () => {
  const { isLoading, error, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const  { userStore, modalStore, workflowStore, companyStore, scoreStore } = useStore();
 
  const navigate = useNavigate();
  
  // useEffect(() => {
  //   const currentPath = window.location.pathname; // Get the current path

  //   // Adjusted logic to not redirect to /login if the current path is /triage
  //   // and the user is not authenticated.
  //   if (!isLoading && !isAuthenticated && !currentPath.startsWith("/triage")) {
  //     navigate('/login');
  //   } else if (isAuthenticated) {
  //     const setToken = async () => {
  //       const token = await getAccessTokenSilently();
  //       store.commonStore.setToken(token);

  //       const fetchMe = async () => {
  //         console.log("fetch me");
  //         if(!userStore.me) {
  //           await userStore.loadMe();
  //           console.log("fetch me 1");
  //           if(userStore.me!.isOnboarded) {
  //             console.log("fetch me 2");
  //             await userStore.loadRecommendations();
  //             await companyStore.getUsers();
  //             await workflowStore.loadFlows();
  //             await companyStore.listCompanies();
  //             await workflowStore.loadModes();
  //             userStore.createHubConnection(userStore.me!.id);

  //             navigate(`/${userStore.me!.lastCompanyId!}`);
  //           }
  //         }
  //       }

  //       fetchMe();
  //     }

  //     setToken();
  //   }
  // }, [isLoading, isAuthenticated, userStore, scoreStore, window.location.pathname]); // Add location.pathname to the dependency array

  useEffect(() => {
    const currentPath = window.location.pathname; // Get the current path
  
    // Immediately redirect unauthenticated users not accessing /triage
    if (!isLoading && !isAuthenticated && !currentPath.startsWith("/triage")) {
      navigate('/login');
    } else if (!isLoading && isAuthenticated) {
      // Define an async function to handle token retrieval and API calls
      const handleAuthenticatedUser = async () => {
        const token = await getAccessTokenSilently();
        store.commonStore.setToken(token);
  
        // Ensure the user data is loaded
        if (!userStore.me) {
          await userStore.loadMe();
          if (userStore.me!.isOnboarded) {
            // Redirect to the last known companyId or a default path if not available
            const companyId = userStore.me!.lastCompanyId || '';
            const targetPath = `/${companyId}`;
            console.log('curre vs targetp', currentPath, targetPath);
            if(!currentPath.startsWith("/callback")) {
              if (currentPath !== targetPath) {
                navigate(targetPath);
                
              } 
            }

            
            // Now that we're at the correct path, make the rest of the API calls
            await userStore.loadRecommendations();
            await companyStore.getUsers();
            await workflowStore.loadFlows();
            await companyStore.listCompanies();
            await workflowStore.loadModes();
            userStore.createHubConnection(userStore.me!.id);
          
          } else {
            // Handle cases where the user is not onboarded
            // This could redirect to an onboarding process if not yet completed
            if (!currentPath.startsWith("/onboarding")) {
              navigate('/onboarding');
            }
          }
        }
      };
  
      handleAuthenticatedUser();
    }
  }, [isLoading, isAuthenticated, navigate, getAccessTokenSilently, userStore, companyStore, workflowStore]);
  
  
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading ) {
    return <Loading />;
  }
   
  if(isAuthenticated && userStore.me == null)
    return <div></div>

  if(isAuthenticated && !userStore.me?.isOnboarded)
    return <Onboarding/>

  return (
        <div id="app" className="d-flex flex-column h-100">

          <AlertModal message={modalStore.message} show={modalStore.showModal} onClose={modalStore.closeModal} />
 
          <NavBar />
            <Container className="flex-grow-1 mt-5">
            <Routes>
              <Route path="/:companyId" element={<Dashboard />} />
              <Route path="/:companyId/tasks" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/triage/:triageRequestId/:result" element={<Triage />} />
              <Route path="/callback" element={<Callback/>} />
              <Route path="/:companyId/callback" element={<CallbackProcess/>} />
              <Route path="/profile" element={<Profile/>} />  
              <Route path="/welcome" element={<Onboarding/>} />
              <Route path="/:companyId/plugins" element={<PluginsComponent/>} />
              <Route path="/:companyId/apps" element={<Apps/>} />
              <Route path="/:companyId/users" element={<CompanyUsers/>} />
              <Route path="/:companyId/flows" element={<WorkflowBuilder/>} />
              <Route path="/:companyId/performance" element={<Performance/>} />
              <Route path="/:companyId/activity" element={<Activity/>} />
              <Route path="/:companyId/scores/:scoreUrl/:date" element={<ScoreWidget/>} />
              <Route path="/threads/:threadId" element={<Tasks/>} />
              <Route path="/redir/:threadId" element={<Redirector/>} />
            </Routes>
          </Container>
          {/* <Footer /> */}
        </div>
  );
};

export default observer(App);
