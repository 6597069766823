import React, { useState, useEffect } from 'react';
import { WorkflowActionObject } from '../../../models/workflow';
import { useStore } from '../../../stores/store';

interface PagerWidgetProps {
  updateAction: (indexPath: number[], field: keyof WorkflowActionObject, value: any) => void;
  indexPath: number[];
}

const PagerWidget: React.FC<PagerWidgetProps> = ({  updateAction, indexPath }) => {
  

  const  { integrationStore} = useStore();


  const [type, setType] = useState("");
  const [externalId, setExternalId] = useState("");

  useEffect(() => {
    // Mock API call
    // const fetchDropdownOptions = async () => {
    //     await integrationStore.loadSetupData("todoist");
    // };

    // fetchDropdownOptions();
  }, []);

  useEffect(() => {
    // Update the parent component whenever the inputs change
    const jsonValue = JSON.stringify({ type, externalId });
    updateAction(indexPath, 'input', jsonValue);
  }, [type, externalId, indexPath, updateAction]);

  // if(integrationStore.resyncRequired && integrationStore.resyncRequired.has("todoist"))
  //   return <>Connect again... Something is not right</>

  return (
    <>
      number/email:
      <input type="text"
        onChange={(e) => setExternalId(e.target.value)}
        className='form-control mb-2'/>
        

      <select 
        className='form-control mb-2'
        value={type} 
        onChange={(e) => setType(e.target.value)}
        disabled={integrationStore.loadingInitial}
      >
        <option value="">Select</option>
        <option key={1} value={0}>Email</option>
        <option key={2} value={1}>Mobile</option>
        <option key={3} value={2}>Slack</option>
      </select>
     
    </>
  );
};

export default PagerWidget;
