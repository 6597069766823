import React, { Fragment } from "react";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import RatingDots from "../components/RatingDots";

const Dashboard: React.FC = () => {
    const  { userStore } = useStore();
 

  return (
    <Fragment>
      <h1>Welcome back, {userStore.me!.name}</h1>
      <h4>Whats are you working on right now?</h4>
      <input type="text" className="form-control mb-4" placeholder="Enter project / highlevel task name"></input>
     
        <div className="card mb-4">
            <div className="card-body">

            Fundraising for Wally and Whiz
            
            <div className="row">
                Impact: <RatingDots readOnly={true} selected={3} /> Contributions: <RatingDots readOnly={true} selected={5} /> 
            </div>
            </div>
        </div>
        <div className="card mb-4">
            <div className="card-body">

            HoW flagship store
            
            <div className="row">
                Impact: <RatingDots readOnly={true} selected={3} /> Contributions: <RatingDots readOnly={true} selected={5} /> 
            </div>
            </div>
        </div>
        <div className="card mb-4">
            <div className="card-body">

            MVP Baseline
            
            <div className="row">
                Impact: <RatingDots readOnly={false} selected={3} /> Contributions: <RatingDots readOnly={false} selected={5} /> 
            </div>
            </div>
        </div>
        

    </Fragment>
  );
};

export default observer(Dashboard);
