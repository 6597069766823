import { makeAutoObservable } from "mobx";

export default  class ModalStore {
  showModal = false;
  message = "";

  constructor() {
    makeAutoObservable(this);
  }

  openModal = (message: string) => {
    this.message = message;
    this.showModal = true;
  }

  closeModal = () => {
    console.log("Closing modal", this);
    this.showModal = false;
    this.message = "";
  }
}
