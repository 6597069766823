import { Thread } from "./thread";
import { User } from "./user";

// Priority of the task
export enum TaskPriority {
    Low,
    Medium,
    High
}

// Effort of the task
export enum TaskEffort {
    Low,
    Medium,
    High
}

// Refers to the different types a message can be
export enum MessageType {
    
    Undefined,
    //These are marketing emails sent to promote a product, service, or event. They often include special offers, discounts, or other incentives to encourage the recipient to take action.
    Promotional,
    // Sent on a regular basis (e.g., daily, weekly, monthly), these emails keep subscribers informed about the latest news, updates, or content related to a particular topic or business.
    Newsletter,
    //As you mentioned, these are sent in response to a transaction or action taken by the recipient. Examples include purchase confirmations, shipping notifications, and password resets.
    Transactional,
    // These are sent to inform the recipient about something important or new, like changes in terms of service, privacy policy updates, or informational alerts.
    Informational,
    //These are individual and non-commercial in nature, used for personal communication between friends, family, and acquaintances.
    Personal,
    //These are triggered by specific actions or events, such as welcome emails when someone signs up for a service, or reminder emails for events or appointments.
    Automated,
    //These are based on the recipient's behavior, preferences, or previous interactions with the sender. For example, a follow-up email might be sent if a customer abandons a shopping cart.
    Behavioral,
    //Notifications from social media platforms about activities like new followers, comments, or mentions.
    SocialMedia,
    //These focus on teaching or providing information on a specific topic, often part of an online course or educational series.
    Educational,
    //Sent to gather opinions or feedback from recipients about a product, service, or experience.
    SurveyAndFeedback 
}

// The tasks' category 
export enum TaskCategory {
    Undefined,
    Review,
    Payment,
    Meeting,
    Update,
    Decision,
    Action,
    Information,
    Assistance,
    Submission,
    Confirmation,
    Feedback,
    Opportunity,
    Reminder,
    Introduction,
    Proposal
}

// Message refers to an email
export interface Message {
    // Type of email
    type: MessageType
}

export interface Task {
    id: string;
    parentTaskId?: string;
    body: string;
    title: string;
    createdBy: User;
    user: User;
    dueDate?: string;
    thread: Thread;
    status: TaskStatus;
    priority: TaskPriority;
    effort: TaskEffort;
    replyExpected: boolean;
    category: TaskCategory
    message: Message
}


export interface TaskSolutionSuggestion {
    suggestion: string;
}

export enum TaskStatus {
    Pending,
    Completed,
    Deleted
}
