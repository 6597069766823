import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useStore } from "../stores/store";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import SingleFlow from "../components/Workflow/SingleFlow";
import { WorkflowStatus } from "../models/workflow";
import { UserTrait } from "../models/userTrait";
import { getCompanyIdFromPath } from "../utils/url";




interface Pill {
  id: number;
  label: string;
}


const pills: Pill[] = [
  { id: 1, label: '💤 Peace and Quiet' },
  { id: 2, label: '🚀 Boost Productivity' },
  { id: 3, label: '📅 Organize Schedules' },
  { id: 4, label: '🔔 Stay Updated' },
  { id: 5, label: '📧 Manage Emails' },
  { id: 6, label: '🤝 Collaborate Efficiently' },
  { id: 7, label: '🎯 Set Goals' },
  { id: 8, label: '📊 Analyze Performance' },
  { id: 9, label: '🔒 Enhance Security' },
  { id: 10, label: '🌱 Personal Growth' },
  // ... add more as needed
];


const modes: Pill[] = [
  { id: 1, label: '📊 Meetings mode' },
  { id: 2, label: '👩‍💻 Deep work mode ' },
  { id: 3, label: '🏖️ Off mode' },
  { id: 4, label: '✨ Response mode' },
  // ... add more as needed
];

const OnboardingComponent = () => {

  const { user } = useAuth0();
  const { userStore, workflowStore, companyStore } = useStore();


  // New state for slider value
  const [sliderValue, setSliderValue] = useState<number>(3); // Assuming 3 is the middle value
 // Function to handle slider change
 const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newValue = parseInt(event.target.value);
  console.log(newValue);
  setSliderValue(newValue);
};

// Function to get explanation based on slider value
const getSliderExplanation = () => {
  switch(sliderValue) {
    case 1: return "You're primarily a maker, focusing on creating and building.";
    case 2: return "You lean towards making but also manage some tasks.";
    case 3: return "You're balanced between making and managing, adapting as needed.";
    case 4: return "You lean towards managing but also involve yourself in making.";
    case 5: return "You're primarily a manager, focusing on organizing and directing.";
    default: return "Select a position that best describes your role.";
  }
};


  // const createLabels = async () => {
  //   await userStore.createLabels();
  //   await userStore.loadMe();
  // }

  useEffect(() => {
    console.log("updates");
  }, [workflowStore.suggestions, workflowStore]);

  const welcomeRules = async () => {
    await workflowStore.suggestFlowTemp(input);
  }

  const createCompany = async (name: string) => {
    var companyId = await companyStore.createCompany(name, true);
    setCompanyId(companyId!);
  }

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [companyName, setCompanyName] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [selectedModes, setSelectedModes] = useState<number[]>([]);
  const [selectedPills, setSelectedPills] = useState<number[]>([]);
  const [input, setInput] = useState<string>("");


  const togglePillSelection = (id: number) => {
    setSelectedPills(prevSelectedPills =>
      prevSelectedPills.includes(id) ? prevSelectedPills.filter(pillId => pillId !== id) : [...prevSelectedPills, id]
    );
  };
  const toggleModeSelection = (id: number) => {
    setSelectedModes(prevSelectedPills =>
      prevSelectedPills.includes(id) ? prevSelectedPills.filter(pillId => pillId !== id) : [...prevSelectedPills, id]
    );
  };
  
  const activateSuggestions = () => {
    workflowStore.suggestions?.map(async (w) => {
      await workflowStore.changeStatus(w.id, WorkflowStatus.Active, null);
    });
    nextStep();
  
  }
  
  const skip = async () => {

    setCurrentStep(currentStep + 2);
  }

  const nextStep = async () => {
    if (currentStep === 3 && selectedPills.length === 0) {
      return; // Disable the Next button if no pill is selected in step 2
    }
    
    setCurrentStep(currentStep + 1);

    if(currentStep == 2) {
      await createCompany(companyName);
      await userStore.loadMe();
    }

    if(currentStep == 3) {
      await companyStore.upsertTrait(companyId, UserTrait.MakerManagerRatio, ((sliderValue-1)*25).toString());
      await welcomeRules();
    }
    
    if(currentStep == 5) {
      await userStore.onboard();
      await userStore.loadMe();

      // TODO: avoid
      window.location.href = `/${companyId}`;
    }

    
  };


  return (
    <div className="d-flex full-height">
      <div className="w-50 full-height left-pane">
        <div className="container p-4">
          {/* Content for each step */}
          {currentStep === 1 && (
            <div>
              <h2>Baseline</h2>
              <p>70% of knowledge workers are burnt out with work communication overload, spend more than 5 hours a day on work communication, and can’t get their actual jobs done. The goal of Inbox0 is to keep you as distraction free as possible so you can get back to accomplishing your goals. </p>
              
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <h2>Create your company</h2>
              <p> Lorem ipsum...</p>
              <input type="text" className="form-control" placeholder="Enter company name..." onChange={(e) => setCompanyName(e.target.value)}/>
            </div>
          )}
          {currentStep === 3 && (
            <div>
              <h2>How do you work?</h2>
              Most people tend to switch between different modes while working. We will start by creating these. The normal behavior is something like:
              <div>
              {modes.map(pill => (
                    <div
                      key={pill.id}
                      className={`badge mr-2 badge-pill ${selectedModes.includes(pill.id) ? 'badge-primary' : 'badge-light'}`}
                      onClick={() => toggleModeSelection(pill.id)}
                    >
                      {pill.label}
                    </div>
                  ))}
              </div>
              
         
         
    {/* Question for the user */}
    <label>Are you a maker or manager?</label>
    {/* Container for slider and labels */}
    <div style={{ margin: '20px 0' }}>
      {/* Slider labels for Maker and Manager */}
      <div className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
        <span>Maker</span>
        {/* Optionally, you can add a midpoint label if it makes sense for your use case */}
        <span>Manager</span>
      </div>
      {/* The slider itself */}
      <input
        type="range"
        className="custom-slider"
        min="1"
        max="5"
        value={sliderValue.toString()}
        onChange={handleSliderChange}
        style={{ width: '100%' }} // Ensure the slider takes up the full width
      />
    </div>
    {/* Explanation based on slider value */}
    <p>{getSliderExplanation()}</p>


              <hr/>
              <div className="form-group">
                <label>What is your main objective:</label>
                <div>
                  {pills.map(pill => (
                    <div
                      key={pill.id}
                      className={`badge mr-2 badge-pill ${selectedPills.includes(pill.id) ? 'badge-primary' : 'badge-light'}`}
                      onClick={() => togglePillSelection(pill.id)}
                    >
                      {pill.label}
                    </div>
                  ))}
                </div>
                <label className="mt-4">Do you have any other wishes:</label>
                <textarea className="form-control" onChange={(e) => setInput(e.target.value)} placeholder="e.g. 'Extract tasks from all my emails from 'acme.inc' and unsubscribe all newsletters'"></textarea>
                {/* <button className='btn btn-primary mt-2' onClick={() => welcomeRules()}>Create standard rules and start monitoring</button> */}
              </div>
              
             
            </div>
          )}
          {currentStep === 4 && (
            <div>
              {workflowStore.suggestions && (
                <>
                  <h2>These rules might help</h2>
                  We have designed some rules that might help you achieve your objectives. Review them below and proceed to activate them.
                  <div className="mt-4">

                    {workflowStore.suggestions.length == 0 && <>Couldn't find any right now.... Todo: Better UI</>}
                    {workflowStore.suggestions.map((w) => {
                      return <SingleFlow flow={w} />
                    })}
                  </div>
                </>
              )}

              {workflowStore.suggestions == null && (
                <>
                  <h2>Proposing optimizations...</h2>
                  Hang on while while we analyze and try to propose some improvements for you
                </>
              )}

              {workflowStore.suggestions != null && (
                <button className="btn btn-primary mt-4" onClick={activateSuggestions} disabled={(currentStep == 4 && workflowStore.suggestions == null)}>
                  {workflowStore.suggestions && workflowStore.suggestions.length == 0 ? 'Next' : 'Looks good'}
                </button>
              )}
            </div>
          )}
          {currentStep === 5 && <h2>All good and ready to start?</h2>}

          {currentStep != 4 && (
          <>
            <button className="btn btn-primary mt-2" onClick={nextStep} disabled={(currentStep === 2 && companyName.length === 0) || (currentStep === 3 && selectedPills.length === 0) || (currentStep == 4 && workflowStore.suggestions == null)}>
              {currentStep < 5 ? 'Next' : 'OK'}
            </button>
          
          {currentStep == 3 && <button className="btn btn-light ml-2 mt-2" onClick={skip}>Skip</button>}
          </>  
          )}
        </div>
      </div>

      
      <div className="w-50 full-height right-pane">

        {/* Right pane with colored background and image */}
        <div className={`right-pane step-${currentStep}`}>
          {/* Add specific styles and images for each step */}
          <img src="https://marketingplatform.com/wp-content/uploads/2021/02/flowbuilder-graph.png"></img>
        </div>
      </div>

    </div>
  );

};

export default withAuthenticationRequired(observer(OnboardingComponent), {
  onRedirecting: () => <Loading />,
});
