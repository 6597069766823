import { makeAutoObservable, reaction } from "mobx";
import { Component } from "react";

export default class CommonStore {
    error: any | null = null;
    token: string | null = window.localStorage.getItem("jwt");
    appLoaded = false;
    currentPath: string = "";

    constructor() {
        makeAutoObservable(this);


        
        reaction(
            () => this.token,
            token => {
                if(token) {
                    window.localStorage.setItem('jwt', token)
                } else {
                    window.localStorage.removeItem('jwt')
                }
            }
        )
    }

    setCurrentPath(path: string) {
        this.currentPath = path;
    }

    setToken = (token: string | null) => {
        this.token = token;
        console.log("set token", token);
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }

    setServerError = (error: any) => {
        this.error = error;
        
    }
}