// src/views/Login.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { loginWithRedirect ,isAuthenticated} = useAuth0();

  React.useEffect(() => {

    if (isAuthenticated) {
      return;
    }

    async function checkUser() {
      if (!isAuthenticated) {
        loginWithRedirect({
          authorizationParams: {
            useRefreshTokens: true,
            access_type: 'offline',
            scope: 'offline_access',
            approval_prompt: 'force',
            connection_scope: 'https://www.googleapis.com/auth/gmail.modify, https://www.googleapis.com/auth/calendar.events, https://www.googleapis.com/auth/userinfo.email, https://www.googleapis.com/auth/userinfo.profile'
          }
        });
      }
    }

    checkUser();

  }, [isAuthenticated, loginWithRedirect]);

  return <div>Loading...</div>;
};

export default Login;
