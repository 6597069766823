import { makeAutoObservable } from "mobx";
import agent from "../api/agent";

export default class LogStore {
    
    loadingInitial = false;
    logs: Log[] | null = null;
  
    loadLogs = async () => {
        this.setLoadingInitial(true);
        try {
           this.logs = await agent.Logs.list();
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    constructor() {
        makeAutoObservable(this);
    }
}