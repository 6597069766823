import React from 'react';

type TaskSortingButtonsProps = {
  sortByResponsible: () => void;
  sortByCategory: () => void;
  sortByDate: () => void;
  sortByPriority: () => void;
};

const TaskSortingButtons: React.FC<TaskSortingButtonsProps> = ({
  sortByResponsible,
  sortByDate,
  sortByCategory,
  sortByPriority
}) => {
  return (
    <div className="btn-group mb-4">
      <button className="btn btn-secondary" onClick={sortByResponsible}>By Responsible</button>
      <button className="btn btn-secondary" onClick={sortByDate}>By Date</button>
      {/* <button className="btn btn-secondary" onClick={sortByCategory}>By Category</button>
      <button className="btn btn-secondary" onClick={sortByPriority}>By Priority</button> */}
    </div>
  );
};

export default TaskSortingButtons;
