import React from 'react';
import { User } from '../models/user';

type AvatarProps = {
  user: User,
  showName?: boolean,
  showEmail?: boolean
};

const Avatar: React.FC<AvatarProps> = ({
  user,
  showEmail = true,
  showName = true
}) => {
  return (
    <span className="">
      <img src={user.avatar} className='rounded-circle img-fluid' width={24}/>
       {showName && user.name}
        {showEmail && <span className='badge badge-secondary'>{user.email}</span>}
    </span>
  );
};

export default Avatar;
