import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { GroupedScore, Score } from "../models/score";
import { Reflection, ReflectionLog } from "../models/reflection";

export default class ScoreStore {
    
    loadingInitial = false;
    scores: GroupedScore[]  = [];
    reflections: Reflection[] = [];
    reflectionLogs: ReflectionLog[] = [];
  

    listScores = async (fromTimestamp?: string | null) => {
        this.setLoadingInitial(true);
        try {
           this.scores = await agent.Scores.list(fromTimestamp);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    addReflection = async (id: string) => {
        try {
            await agent.Reflections.add(id);
        } catch(error) {

        }
    }

    createReflectionTag = async (name: string) => {
        try {
            var reflection = await agent.Reflections.createTag(name);
            this.reflections.push(reflection);
            return reflection;
        } catch(error) {

        }
    }

    listReflections = async () => {
        this.setLoadingInitial(true);
        try {
           this.reflections = await agent.Reflections.list();
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    listReflectionLogs = async (date: string) => {
        this.setLoadingInitial(true);
        try {
           this.reflectionLogs = await agent.Reflections.logList(date);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    constructor() {
        makeAutoObservable(this);
    }
}