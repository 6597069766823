import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Task, TaskStatus, TaskPriority, TaskCategory } from '../models/task';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingIndicator from '../components/LoadingIndicator';
import TaskSortingButtons from '../components/Tasks/TaskSortingButtons';
import SortOrderButtons from '../components/Tasks/SortOrderButtons';
import ResultBlock from '../components/Tasks/ResultBlock';
import TaskList from '../components/Tasks/TaskList';
import { Thread, ThreadImportStatus } from '../models/thread';
import ProcessingIndicator from '../components/ProcessingIndicator';
import ThreadHeader from '../components/ThreadHeader';
import TaskSummary from '../components/Tasks/TaskSummary';

interface TasksComponentState {
  showResult: boolean;
  apiMessage: string;
  error: Error | null;
}


type GroupedTasks = {
  [key: string]: Task[];
};  

type SortOrder = 'asc' | 'desc';

const TasksComponent = () => {
  const { threadId } = useParams<{ threadId?: string }>();
  const { taskStore, threadStore } = useStore();

  const [state, setState] = useState<TasksComponentState>({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  const [isPolling, setIsPolling] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [draft, setDraft] = useState("");
  const [sortCriterion, setSortCriterion] = useState<string>('responsible');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        await taskStore.loadTasks();
        setTasks(taskStore.tasks);
      } catch (error) {
        console.error('Failed to load tasks:', error);
        setState(prevState => ({ ...prevState, error: error as Error }));
      }
    };

    fetchTasks();

    if(threadId) {
      fetchThread();
    } else {
      threadStore.getThread(null);
    }

  }, [taskStore, threadId]);


  const fetchThread = async () => {
    try {
      await threadStore.getThread(threadId!);
      if (threadStore.thread!.status !== ThreadImportStatus.Completed) {
        setIsPolling(true);
      } else {
        setIsPolling(false);
      }
    } catch (error) {
      console.error('Failed to load tasks:', error);
      setState(prevState => ({ ...prevState, error: error as Error }));
      setIsPolling(false);
    }
  };
  
  
  useEffect(() => {
    let intervalId: number | undefined;
  
    if (isPolling) {
      intervalId = window.setInterval(() => {
        fetchThread();
      }, 5000);
    }
  
    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
  }, [isPolling, threadId]);
  

  
  const taskStatusChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const isChecked = e.target.checked;
    
    changeStatus(id, isChecked);
  };

  const changeStatus = async (id: string, isChecked: boolean) => {
    var body = "";
    if(isChecked) {
      body = ""; //prompt("Internal Inbox0 note? The sender will not see this") || "";
    }

    await taskStore.setStatus(id, isChecked ? TaskStatus.Completed : TaskStatus.Pending, body);
  }

  const taskDelete = async (task:Task) => {
    await taskStore.deleteTask(task);
  }

    const taskSolve = async (task:Task) => {

    var suggestionInput = prompt("Additional input to suggest an answer (optional). If empty, no chat gpt response will be created. If non-empty, the chat gpt response will be the next prompt. OBS: Wait wait wait after click - Missing UI loader");

    var suggestion = "";

    if(suggestionInput != null && suggestionInput!.length > 0) {
     await taskStore.solveSuggestion(task.id, suggestionInput!);
     suggestion = taskStore.activeSuggestion?.suggestion!;
    }

    setDraft(suggestion);
    
  }

  // const taskDraftReply = async (reply: string, task:Task) => {
  //   await taskStore.solve(task.id, reply);

  //   if(window.confirm("Close task?")) {
  //     changeStatus(task.id, true);
  //   }
  //   alert("Refresh Gmail");
  // }

  const sendUpdate = async () => {
    if(threadId) {
      //await threadStore.createDraft(threadId);
      alert("Not possible anymore. This should not be a feature.... ! ");
    }
  }

  const toggleEditMode = (taskId: string, title: string) => {
    setEditingTaskId(taskId);
    setEditedTitle(title);
  };

  const selectTask = (task: Task) => {
    taskStore.selectTask(task);

  }

  const reassign = async (task: Task) => {
    var toWho = prompt("To do how do you want to reassign (email) ? ")!;
    taskStore.reassignTask(task, toWho);
    await threadStore.getThread(threadId!);
  }

  const saveTitleChange = (taskId: string) => {
    taskStore.updateTitle(taskId, editedTitle);
    setEditingTaskId(null);
  };


  const getPriorityValue = (priority: TaskPriority) => {
    switch (priority) {
      case TaskPriority.High:
        return 3;
      case TaskPriority.Medium:
        return 2;
      case TaskPriority.Low:
        return 1;
      default:
        return 0;
    }
  };

  const groupTasks = (): GroupedTasks => {
    const grouped: GroupedTasks = {};
    tasks.forEach(task => {
      let groupKey: string;
      switch (sortCriterion) {
        case 'responsible':
          groupKey = task.user.name;
          break;
          // Add more cases as necessary for different sortCriteria
        case 'date':
          groupKey = task.dueDate!;
          break;
        // Add more cases as necessary for different sortCriteria
        default:
          groupKey = ""; // Default case, or throw an error if the criterion is not recognized
      }
      if (!grouped[groupKey]) {
        grouped[groupKey] = [];
      }
      grouped[groupKey].push(task);
    });
    return grouped;
  };
  
  const sortTasks = (): GroupedTasks => {
    let sortedTasks = [...tasks];
  
    // Apply sorting logic based on sortCriterion and sortOrder
    switch (sortCriterion) {
      case 'responsible':
        sortedTasks.sort((a, b) => {
          const nameA = a.user.name.toLowerCase();
          const nameB = b.user.name.toLowerCase();
          return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });
        break;
      // Add other sorting cases here
      default:
        // Default sorting logic if needed
        break;
    }
  
    // Grouping logic
    const grouped: GroupedTasks = {};
    sortedTasks.forEach(task => {
      // Grouping key based on sorted tasks
      const groupKey = task.user.name; // Example for 'responsible'
      if (!grouped[groupKey]) {
        grouped[groupKey] = [];
      }
      grouped[groupKey].push(task);
    });
  
    return grouped;
  };  

  const updateSorting = (criterion: string, order: SortOrder) => {
    setSortCriterion(criterion);
    setSortOrder(order);
  };

  // Handlers for sorting
  const sortByResponsible = () => updateSorting('responsible', sortOrder);
  const sortByCategory = () => updateSorting('category', sortOrder);
  const sortByDate = () => updateSorting('date', sortOrder);
  const sortByPriority = () => updateSorting('priority', sortOrder);
  const sortByAscending = () => updateSorting(sortCriterion, 'asc');
  const sortByDescending = () => updateSorting(sortCriterion, 'desc');


  if(threadId && (!threadStore.thread || (threadStore.thread && threadStore.thread.status == ThreadImportStatus.Pending))) {
    return (
      <div>
          <ThreadHeader />
          <ProcessingIndicator />
        </div>
    );
  }

  return (
    <div>
      {taskStore.loadingInitial ? (
        <LoadingIndicator />
      ) : (
        <div >
          <div>

            {(threadId) && (
              <>
                <button onClick={sendUpdate}>Send update</button>
                <a href={`https://mail.google.com/mail/u/0/#search/${threadStore.thread?.externalId}`} target="_blank" rel="noopener noreferrer">
                Open thread
                </a>
              </>
            )}

            <ThreadHeader />
            
            <TaskSortingButtons
              sortByResponsible={sortByResponsible}
              sortByCategory={sortByCategory}
              sortByDate={sortByDate}
              sortByPriority={sortByPriority}
            />
            {/* <SortOrderButtons
              sortByAscending={sortByAscending}
              sortByDescending={sortByDescending}
            /> */}

            
<TaskList
      groupedTasks={groupTasks()}
      taskStatus={taskStatusChangeHandler}
      reassign={reassign}
      selectTask={selectTask}
    />

            {state.showResult && (
              <ResultBlock apiMessage={state.apiMessage} />
            )}
          </div>
          {/* <TaskSummary 
              task={taskStore.getActiveTask()} 
              toggleEditMode={toggleEditMode}
              saveTitleChange={saveTitleChange}
              editingTaskId={editingTaskId}
              editedTitle={editedTitle}
              taskStatus={taskStatusChangeHandler}
              setEditedTitle={setEditedTitle}
              draft={draft}
              setDraft={setDraft}
              loadingSuggestion={taskStore.loadingSuggestion}
              taskDraftReply={taskDraftReply}
              taskDelete={taskDelete}
              taskSolve={taskSolve}/> */}
        </div>
      )}
    </div>
  );
};

export default withAuthenticationRequired(observer(TasksComponent), {
  onRedirecting: () => <LoadingIndicator />,
});
