import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { User } from "../models/user";
import * as signalR from '@microsoft/signalr' 
import { store } from "./store";
import { WorkflowMode } from "../models/workflowMode";
import { Recommendation } from "../models/recommendation";
import { UserTrait } from "../models/userTrait";
import { FocusModeSwitchResponse } from "../models/foucsModeResponse";

export default class UserStore {
    me: User | null = null;
    loadingInitial = false;
    hubConnection: signalR.HubConnection | null = null;
    reccomnedations: Recommendation[] = [];

    createHubConnection = (teamId: string) => {

        this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl("https://localhost:5291/workflowhub?teamId=" + teamId, {
            accessTokenFactory: () =>  store.commonStore.token!
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

        this.hubConnection.start().catch(error => console.log("Error establishing the connection: ", error));

        this.hubConnection.on("LoadWorkflows", (workflows: string) => {
            runInAction(() => console.log("loads", workflows));
        })

        this.hubConnection.on("ReceiveWorkflowEvent", (event: string) => {

            runInAction(() => 
                {
                    store.modalStore.openModal(event);
                }
            );
        })
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch(error=> console.log("Error stopping"));
    }

    reconnectGoogle = async () => {
        await agent.Users.reconnect();
    }

    setFocusMode = async (mode: WorkflowMode | null, until: Date | null):  Promise<FocusModeSwitchResponse | undefined> => {
        this.setLoadingInitial(true);


        try 
        {
            var resp = await agent.Workflows.setFocusMode(mode ? mode.id : null, until);
            if(mode) {
                mode.until = until?.toString();
            }

            
            // this.me!.currentWorkflowMode = mode;
            
            this.setLoadingInitial(false);

            return resp;

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }

    loadMe = async () => {
        this.setLoadingInitial(true);


        try 
        {
            this.me = await agent.Users.getMe();
            this.setLoadingInitial(false);

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }

    loadRecommendations = async () => {
        this.setLoadingInitial(true);


        try 
        {
            this.reccomnedations = await agent.Users.listRecomendations();
            this.setLoadingInitial(false);

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }

    watchInbox = async (stop: boolean) => {
        this.setLoadingInitial(true);
        try 
        {
            await agent.Emails.watchInbox(stop);
            this.setLoadingInitial(false);

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }
    
    onboard = async () => {
        this.setLoadingInitial(true);
        await agent.Users.onboard();
        this.setLoadingInitial(false);
    }

    setLastCompanyId = async (companyId: string) => {
        await agent.Users.setLastCompanyId(companyId);
    }

    // setRefreshToken = async (token: string) => {
    //     await agent.Users.setRefreshToken(token);
    // }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    constructor() {
        makeAutoObservable(this);
    }
}