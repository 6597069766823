import axios, { AxiosResponse } from 'axios';
import { store } from '../stores/store';
import { User } from '../models/user';
import { Task, TaskSolutionSuggestion, TaskStatus } from '../models/task';
import { Thread } from '../models/thread';
import { Workflow, WorkflowAction, WorkflowActionObject, WorkflowStatus, WorkflowTriggerObject, WorkflowType } from '../models/workflow';
import { WorkflowLog, WorkflowMessageLog } from '../models/workflowLog';
import { Integration } from '../models/integration';
import { WorkflowMode } from '../models/workflowMode';
import { TriageRequest } from '../models/triage';
import { getCompanyIdFromPath } from '../utils/url';
import { UserCompany } from '../models/userCompany';
import { Company } from '../models/company';
import { Recommendation } from '../models/recommendation';
import { GroupedScore, Score } from '../models/score';
import { UserTrait } from '../models/userTrait';
import { FocusModeSwitchResponse } from '../models/foucsModeResponse';
import { Reflection, ReflectionLog } from '../models/reflection';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`

    const companyId = getCompanyIdFromPath();
    
    if (companyId && !(config.url?.startsWith('/Users'))) {
        // Prepend the companyId to the request URL
        // Prepend the companyId to the request URL
        // Ensure this respects your API's expected path structure for companyId
        config.url = `/Companies/${companyId}${config.url}`;
    }
    
    return config;
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const Tasks = {
    list: () => requests.get<Task[]>(`/Tasks`),
    listSinceLast: () => requests.get<Task[]>(`/Tasks/sinceLast`),
    delete: (id: string) => requests.del(`Tasks/${id}`),
    setStatus: (id: string, status: TaskStatus, body: string) => requests.post(`/Tasks/${id}/status`, { status: status, body: body}),
    updateTitle: (id: string, title: string) => requests.post(`/Tasks/${id}/title`, { title: title }),
    solve: (id: string, query: string) => requests.post(`/Tasks/${id}/solve`, { query: query }),
    reassign: (id: string, email: string) => requests.post(`/Tasks/${id}/reassign`, { receiver: email }),
    solveSuggestion: (id: string, query: string) => requests.post<TaskSolutionSuggestion>(`/Tasks/${id}/solve/suggestion` , { query: query })    
}

const Threads = {
   // createDraft: (threadId: string) => requests.post(`/Threads/${threadId}/draft`, {}),
    get: (threadId: string) => requests.get<Thread>(`/Threads/${threadId}`),
    getByExternalId: (externalThreadId: string) => requests.get<Thread>(`/Threads/external/${externalThreadId}`),
}

const Triage = {
     triageMessage: (messageId: string, result: string) => requests.post<string>(`/Triage/${messageId}`, { result: result }),
     get: (thriageRequestId: string) => requests.get<TriageRequest>(`/Triage/${thriageRequestId}`),
 }

 const Companies = {
    getUsers: () => requests.get<UserCompany[]>(`/Company/users`),
    switch: (id: string) => requests.post(`/Company/switch`, {})
 }

const Workflows = {
    delete: (id: string) => requests.del(`/Workflows/${id}`),
    logs: () => requests.get<WorkflowLog[]>(`/Workflows/logs`),
    list: () => requests.get<Workflow[]>(`/Workflows`),
    suggest: (input: string) => requests.get<Workflow[]>(`/Workflows/suggest?input=${input}`),
    parse: (json: string) => requests.get<string>(`/Workflows/parse?json=${json}`),
    logMessages: (action: WorkflowAction) => requests.get<WorkflowMessageLog[]>(`/Workflows/logs/messages`),
    changeStatus: (id: string, status: WorkflowStatus, until: Date | null) => requests.post(`/Workflows/${id}/status`, { status: status, until: until }),
    createOrUpdate: (   
        modeId: string | null,
        type: WorkflowType,
        triggers: WorkflowTriggerObject[],
        actions: WorkflowActionObject[],
        name: string
        ) => requests.post(`/Workflows`, { 
            modeId,
            type,
            triggers,
            actions,
            name
        }
    ),
    setFocusMode: (id: string | null, until: Date | null) => requests.post<FocusModeSwitchResponse>(`/Workflows/modes/switch`, { workflowModeId: id, until: until } ),
    createMode: (name: string, label: string) => requests.post(`/Workflows/modes`, { name, label }),
    listModes: () => requests.get<WorkflowMode[]>(`/Workflows/modes`)
}

const Users = {
    getMe: () => requests.get<User>(`/Users/me`),
    reconnect: () => requests.post(`/Users/me/reconnect`, {}),
    listCompanies: () => requests.get<Company[]>(`/Users/me/companies`),
    listRecomendations: () => requests.get<Recommendation[]>(`/Users/me/recommendations`),
    upsertTrait: (companyId: string, trait: UserTrait, value: string) => requests.post(`/Users/me/traits`, { trait, value, companyId }), 
    createCompany: (name: string, autoSelect: boolean) => requests.post<string>(`/Users/me/companies`, { name, autoSelect }),
    onboard: () => requests.post(`/Users/onboard`, {}),

    //createLabels: () =>  requests.post(`/Users/labels`, {}),
    setLastCompanyId: (companyId: string) => requests.post(`/Users/me/lastCompany`, { companyId: companyId } ),
    //setRefreshToken: (token: string) => requests.post(`/Users/me/refreshToken`, { refreshToken: token } )
}

const Logs = {
    list: () => requests.get<Log[]>(`/Logs`)
}

const Scores = {
    list: (fromTimestamp?: string | null) => requests.get<GroupedScore[]>(`/Scores?fromTimestamp=${fromTimestamp}`)
}

const Reflections = {
    add: (id: string) => requests.post(`/Reflections`, { id: id }),
    createTag: (name: string) => requests.post<Reflection>(`/Reflections/tags`, { name: name }),
    list: () => requests.get<Reflection[]>(`/Reflections`),
    logList: (date: string) => requests.get<ReflectionLog[]>(`/Reflections/logs?date=${date}`)
}

const Integrations = {
    add: (id: string, code: string) => requests.post(`/Integrations`, {id: id, code: code}),
    delete: (id: string) => requests.del(`/Integrations/${id}`),
    getConfigData: (id: string) => requests.get(`/Integrations/${id}/config`),
    list: () => requests.get<Integration[]>(`/Integrations`)
}

const Emails = {
    watchInbox: (disable: boolean) => requests.post(`/Emails/watch`, { disable: disable } ),
}

const agent = {
    Tasks,
    Users,
    Threads,
    Integrations,
    Workflows,
    Emails,
    Logs,
    Triage,
    Companies,
    Scores,
    Reflections
}

export default agent;