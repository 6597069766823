import React from 'react';

interface GaugeProps {
  value: number;
}

const Gauge: React.FC<GaugeProps> = ({ value }) => {
  const radius = 100;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;// This calculation ensures the gauge fills from 0 to the value proportionally
  const strokeDashoffset = (circumference / 2) * (1 - value / 100);
  

  // Calculate needle position based on value
  const needleAngle = Math.PI * (1 - value / 100); // Correct angle calculation
  const needleX = 110 + radius * Math.cos(needleAngle); // Adjusted X position
  const needleY = 110 - radius * Math.sin(needleAngle); // Adjusted Y position

  return (
    <svg width="220" height="110" viewBox="0 0 220 110">
      <path
        fill="none"
        stroke="#eee"
        strokeWidth={strokeWidth}
        d={`M10,110 A100,100 0 0,1 210,110`}
      />
      <path
        fill="none"
        stroke="tomato"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference / 2}
        strokeDashoffset={strokeDashoffset}
        d={`M10,110 A100,100 0 0,1 210,110`}
      />
      <line
        x1="110"
        y1="110"
        x2={needleX}
        y2={needleY}
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Gauge;
