import React from "react";
import { useStore } from "../stores/store";


const ThreadHeader: React.FC = () => {
  
  const { taskStore, threadStore } = useStore();
  
  return <div>
      {threadStore.thread ? <h4 className="mb-4">{threadStore.thread.subject}</h4> : <h4 className="mb-4">To-dos</h4>}
      {threadStore.thread ? <h4>All to-dos</h4> : <></>}
  </div>
}

export default ThreadHeader;
