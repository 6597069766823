import { User } from "./user";
import { WorkflowMode } from "./workflowMode";

export enum TriggerProperty {
    Subject = 0,
    Sender,
    Receiver,
    Category,
    DueDate,
    Type,
    ReplyExpected,
    Priority,
    Todos,
    Attachments,
    TriageAnswer,
    TaskStatus,
    TaskEffort
  }

  export enum WorkflowType {
    Email,
    Todo,
    Integration,
    Triage
  }
  
  export enum TriggerCondition {
    NotEqual = 0,
    Equal,
    Gt,
    Lt,
    Ltq,
    Gtq,
    Contains,
    NotContains
  }
  
  export enum TriggerQuantifier {
    All,
    Any
  }
  

  export enum WorkflowStatus {
    Active = 0,
    Paused,
    Suggestion,
    Deleted
  }
  
  export enum WorkflowAction {
    AutoDraft = 0,
    AddLabel,
    Archive,
    Unsubscribe,
    MarkAsRead,
    AddToCalendar,
    HideTodos,
    Slack,
    Todoist,
    Triage,
    Pager,
    MarkAsImportant
  }


export interface Workflow {
    id: string;
    actions: WorkflowActionObject[];
    triggers: WorkflowTriggerObject[];
    type: WorkflowType;
    name: string;
    status: WorkflowStatus;
    until?: string;
    mode?: WorkflowMode;
    //only used when receiving data from the API, not for posting / creating workflows
    created?: string;
    //only used when receiving data from the API, not for posting / creating workflows
    user?: User;
    //only used when receiving data from the API, not for posting / creating workflows
    noOfExecutions?: number;
}

export interface WorkflowActionObject {
    action: WorkflowAction | null
    input: string
    thenTriggerWorkflow?: Workflow | null
}

export interface WorkflowTriggerObject {
    property: TriggerProperty | null
    subproperty: TriggerProperty | null
    condition: TriggerCondition | null
    quantifier: TriggerQuantifier | null 
    input: string;
}