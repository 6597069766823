


export function getConfig() {

    const audience = process.env.REACT_APP_AUDIENCE || "https://tryinbox0.com";
    const domain = process.env.REACT_APP_DOMAIN || "inbox0-dev.eu.auth0.com";
    const clientId = process.env.REACT_APP_CLIENT_ID || "EQDtPvCYCiHsbUy6RdzQdonTZXzlmXJD";
  
    return {
      domain,
      clientId,
      ...(audience && audience !== "YOUR_API_IDENTIFIER" ? { audience } : null),
    };
  }
   