import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import Start from "../views/Start";

const NavBar = () => {
  const { userStore, companyStore,workflowStore } = useStore();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const refreshGoogleToken = async () => {
    userStore.reconnectGoogle();
  }

  const logoutWithRedirect = async () =>

    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

    const changeCompany = async (id: string) => {
      await companyStore.changeCompany(id);
      navigate(`/${id}`);
    }

  const watch = async (stop: boolean) => {

    if(workflowStore.flows.length == 0 && !stop)
    {
      window.alert("No flows , hence no need to turn on the monitor");
      return;
    }
    await userStore.watchInbox(stop);
    await userStore.loadMe();
    await workflowStore.loadModes();
  }


  return (
    <div className="nav-container">
       {userStore.me && !userStore.me!.isInputWatched && (
      <div className="bg-danger p-2"><center>No inbox is monitored currently. Start the monitor under "Start".</center> </div>
       )}
      <Navbar color="white" light expand="md" container={false} >
        <Container>
          {/* <NavbarBrand className="logo" /> */}
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
       
           
{isAuthenticated && (
  <>
                <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to={`/${userStore.me?.lastCompanyId}`}
                >
                  <i className="bi bi-list-check"></i> Baseline
                </NavLink>
                </NavItem>

                <NavItem>
                <NavLink
                    tag={RouterNavLink}
                    to={`/${userStore.me?.lastCompanyId}/tasks`}
                  >
                    Tasks
                  </NavLink>
                </NavItem>
                </>
              )}



            </Nav>
            <Nav className="d-none d-md-block" navbar>
                 
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <div className="inline-elements">
                    <Start></Start>
                    <DropdownToggle nav caret id="profileDropDown"> 
                        <img
                          src={user!.picture}
                          alt="Profile"
                          className="mt-2 nav-user-profile rounded-circle ml-4"
                          width="40"
                        />
                    </DropdownToggle>
                  </div>
                  <DropdownMenu>
                    <DropdownItem header>{user!.name}<br/><small>{companyStore.companies.length > 0 && companyStore.companies.find((x) => x.id == userStore.me!.lastCompanyId)?.name}</small></DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                    >
                      <i className="bi bi-person-circle"></i> Profile
                    </DropdownItem>
                    
                    <DropdownItem
                      tag={RouterNavLink}
                      to={`/${userStore.me!.lastCompanyId}/flows`}
                      className="dropdown-profile"
                    >
                      <i className="bi bi-ui-checks"></i> Automations
                    </DropdownItem>
                    
                    
                    <DropdownItem
                      tag={RouterNavLink}
                      to={`/${userStore.me!.lastCompanyId}/activity`}
                      className="dropdown-profile"
                    >
                      <i className="bi bi-ui-checks"></i> Activity
                    </DropdownItem>
                    
                    <DropdownItem
                      tag={RouterNavLink}
                      to={`/${userStore.me!.lastCompanyId}/performance`}
                      className="dropdown-profile"
                    >
                      <i className="bi bi-ui-checks"></i> Performance
                    </DropdownItem>
                    
                    <DropdownItem
                      tag={RouterNavLink}
                      to={`/${userStore.me!.lastCompanyId}/users`}
                      className="dropdown-profile"
                    >
                      <i className="bi bi-ui-checks"></i> Users
                    </DropdownItem>
                    
                    <DropdownItem
                      tag={RouterNavLink}
                      to={`/${userStore.me!.lastCompanyId}/apps`}
                      className="dropdown-profile"
                    >
                      <i className="bi bi-ui-checks"></i> Apps
                    </DropdownItem>

                    
                    {companyStore.companies.map((company) => {
                      return (
                      <DropdownItem
                          tag={RouterNavLink}
                          to={`${company.id}`}
                          onClick={() => window.location.href = "/" + (company.id)}
                          className="dropdown-profile"
                        >
                          <i className="bi bi-plugin"></i> - {company.name}
                      </DropdownItem>
                      );
                    })}
                    
                    
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => refreshGoogleToken()}
                    >
                      <i className="bi bi-door-open"></i> Reconnect Google
                    </DropdownItem>

                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <i className="bi bi-door-open"></i> Log
                      out
                    </DropdownItem>

                    {!userStore.me!.isInputWatched && (
              <button onClick={async () => await watch(false)}  disabled={userStore.loadingInitial} className="btn ml-2 btn-primary">START <i className="bi bi-play"></i> (ONLY DEBUG)</button>
            )}
            {userStore.me?.isInputWatched && (
              <button onClick={async () => await watch(true)}  disabled={userStore.loadingInitial} className="btn ml-2 btn-danger">STOP <i className="bi bi-pause"></i> (ONLY DEBUG)</button>
            )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              
            </Nav>
    

            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user!.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user!.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/plugins"
                  >
                    Chrome plugins
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default observer(NavBar);
