import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { UserCompany } from "../models/userCompany";
import { Company } from "../models/company";
import { UserTrait } from "../models/userTrait";

export default class CompanyStore {
    

    loadingInitial = false;
    users: UserCompany[] = [];
    companies: Company[] = [];

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCompany = async (name: string, autoSelect: boolean) => {
        this.setLoadingInitial(true);
        try {
            var companyId = await agent.Users.createCompany(name, autoSelect);
            this.setLoadingInitial(false);
            return companyId;
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    upsertTrait = async (companyId: string, trait: UserTrait, value: string) => {
        await agent.Users.upsertTrait(companyId, trait, value);
    }

    changeCompany = async (id: string) => {
        
        this.setLoadingInitial(true);
        try {
            await agent.Companies.switch(id);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    getUsers = async () => {
        this.setLoadingInitial(true);
        try {
            this.users = await agent.Companies.getUsers();
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }
    
    listCompanies = async () => {
        this.setLoadingInitial(true);
        try {
            this.companies = await agent.Users.listCompanies();
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }
    
    constructor() {
        makeAutoObservable(this);
    }
}