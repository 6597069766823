import React from 'react';

type ResultBlockProps = {
  apiMessage: string;
};

const ResultBlock: React.FC<ResultBlockProps> = ({ apiMessage }) => {
  return (
    <div className="result-block-container">
      <div className="result-block" data-testid="api-result">
        {/* Display API message */}
        <span>{JSON.stringify(apiMessage, null, 2)}</span>
      </div>
    </div>
  );
};

export default ResultBlock;
