import React from 'react';
import { Task } from '../../models/task';
import TaskRow from './TaskRow';

type GroupedTasks = {
  [key: string]: Task[];
};

type TaskListProps = {
  groupedTasks: GroupedTasks;
  reassign: (task: Task) => void;
  selectTask: (task: Task) => void;
  taskStatus: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
};

const TaskList: React.FC<TaskListProps> = ({ groupedTasks, selectTask, taskStatus, reassign }) => {
  return (
    <div>
      {Object.keys(groupedTasks).length === 0 ? (
        <div>No tasks extracted yet...</div>
      ) : (
        Object.entries(groupedTasks).map(([group, tasks]) => (
          <div key={group}>
            <h3>{group}</h3>
            {tasks.map(task => (
              <TaskRow
                key={task.id}
                task={task}
                reassign={reassign}
                selectTask={selectTask}
                taskStatus={taskStatus}
              />
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default TaskList;
