import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import TaskStore from "./taskStore";
import ThreadStore from "./threadStore";
import WorkflowStore from "./workflowStore";
import IntegrationStore from "./integrationStore";
import LogStore from "./logStore";
import ModalStore from "./modalStore";
import TriageStore from "./triageStore";
import CompanyStore from "./companyStore";
import ScoreStore from "./scoreStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    taskStore: TaskStore;
    threadStore: ThreadStore;
    workflowStore: WorkflowStore;
    integrationStore: IntegrationStore;
    logStore: LogStore;
    modalStore: ModalStore;
    triageStore: TriageStore;
    companyStore: CompanyStore;
    scoreStore: ScoreStore;
}

export const store: Store = {
    companyStore: new CompanyStore(),
    scoreStore: new ScoreStore(),
    commonStore: new CommonStore(),
    modalStore: new ModalStore(),
    workflowStore: new WorkflowStore(),
    triageStore: new TriageStore(),
    taskStore: new TaskStore(),
    threadStore: new ThreadStore(),
    integrationStore: new IntegrationStore(),
    userStore: new UserStore(),
    logStore: new LogStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}