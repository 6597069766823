import React, { useEffect, useState } from 'react';
import { TaskCategory, MessageType, TaskEffort } from '../models/task';
import { useStore } from '../stores/store';
import { TriggerCondition, TriggerProperty, TriggerQuantifier, Workflow, WorkflowAction, WorkflowActionObject, WorkflowStatus, WorkflowTriggerObject, WorkflowType } from '../models/workflow';
import { observer } from 'mobx-react-lite';
import SingleFlow from '../components/Workflow/SingleFlow';
import SlackWidget from '../components/Workflow/Apps/SlackWidget';
import TodoistWidget from '../components/Workflow/Apps/TodoistWidget';
import { WorkflowMode } from '../models/workflowMode';
import TriageWidget from '../components/Workflow/InternalWidgets/TriageWidget';
import PagerWidget from '../components/Workflow/InternalWidgets/PagerWidget';

const WorkflowBuilder: React.FC = () => {

  const { workflowStore, userStore, companyStore } = useStore();
  const [workflow, setWorkflow] = useState<Workflow>({name: '', status: WorkflowStatus.Paused, id: '', type: WorkflowType.Email, actions: [], triggers: []});
  const [tab, setTab] = useState<number>(0);
  const [mode, setMode] = useState<WorkflowMode|null>(null);
  const [ruleText, setRuleText] = useState<string | null>(null);

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        await workflowStore.loadFlows();
        await workflowStore.loadLogs();
      
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetchFlows(); 

  }, []);

  const getActions = (type: WorkflowType | null) => {
    if(type == WorkflowType.Email)
      return [
        WorkflowAction.AddLabel, 
        WorkflowAction.Archive, 
        WorkflowAction.AutoDraft, 
        WorkflowAction.HideTodos, 
        WorkflowAction.MarkAsRead, 
        WorkflowAction.Unsubscribe,
        WorkflowAction.Triage,
        WorkflowAction.MarkAsImportant,
        WorkflowAction.Pager
      ];
    
    if(type == WorkflowType.Todo)
      return [
        WorkflowAction.AddToCalendar, 
      ];

    if(type == WorkflowType.Integration)
      return [
        WorkflowAction.Slack, 
        WorkflowAction.Todoist, 
      ];

    if(type == WorkflowType.Triage)
    return [
      WorkflowAction.Pager
    ];

    return [];
  }

  const getTriggerQuantifier = (trigger: TriggerProperty | null) => {
    return [
      TriggerQuantifier.All,
      TriggerQuantifier.Any
    ]
  }


  const getTriggerSubProperty = (trigger: TriggerProperty | null) => {
    return [
      TriggerProperty.Category, 
      TriggerProperty.TaskStatus, 
      TriggerProperty.DueDate, 
      TriggerProperty.Priority, 
      TriggerProperty.ReplyExpected, 
      TriggerProperty.TaskEffort 
    ]
  }


  const getTriggerProperties = (type: WorkflowType | null) => {
    if(type == WorkflowType.Email)
      return [
        TriggerProperty.Receiver, 
        TriggerProperty.Sender, 
        TriggerProperty.Subject, 
        TriggerProperty.Type,
        TriggerProperty.Todos
      ];
      
   
    if(type == WorkflowType.Triage)
      return [
        TriggerProperty.TriageAnswer,
      ];
      
   
      return [
        TriggerProperty.TaskStatus, 
        TriggerProperty.Category, 
        TriggerProperty.DueDate, 
        TriggerProperty.Priority, 
        TriggerProperty.ReplyExpected, 
      ];
  }

  const getConditionOptions = (property: TriggerProperty | null): TriggerCondition[] => {
    switch (property) {
      case TriggerProperty.Subject:
      case TriggerProperty.Sender:
        return [TriggerCondition.NotEqual, TriggerCondition.Equal, TriggerCondition.NotContains, TriggerCondition.Contains];
      case TriggerProperty.ReplyExpected:
      case TriggerProperty.Receiver:
      case TriggerProperty.Priority:
        return [TriggerCondition.NotEqual, TriggerCondition.Equal];
      case TriggerProperty.Todos:
      case TriggerProperty.Type:
      case TriggerProperty.Category:
      case TriggerProperty.DueDate:
      case TriggerProperty.TriageAnswer:
        return [TriggerCondition.NotEqual, TriggerCondition.Equal, TriggerCondition.Gt, TriggerCondition.Lt, TriggerCondition.Ltq];
      default:
        return [];
    }
  };

  const removeTrigger = (index: number, indexPath: number[]) => {


    setWorkflow((prevWorkflow) => {
      console.log(index, indexPath, 'removing trigger');
      
      // Create a copy of the currentWorkflow to avoid overwriting
      let currentWorkflow = { ...prevWorkflow };
      
      // Create a reference to the parentWorkflow
      let parentWorkflow = currentWorkflow;
  
      // Traverse the nested actions based on the indexPath
      for (const index of indexPath) {
        if (currentWorkflow.actions[index]?.thenTriggerWorkflow) {
          console.log("found");
          parentWorkflow = currentWorkflow;
          currentWorkflow = currentWorkflow.actions[index].thenTriggerWorkflow!;
        }
      }
  
      // Remove the trigger from the currentWorkflow (nested workflow)
      currentWorkflow.triggers.splice(index, 1);
  
      return parentWorkflow;
    });

    // console.log(indexPath, "tryingt oremovetrigger");
    
    // setWorkflow(prevWorkflow => {
    //   // Create a new array of triggers, excluding the one at the specified index
    //   const updatedTriggers = prevWorkflow.triggers.filter((_, i) => i !== index);
  
    //   // Return the updated workflow object, ensuring immutability
    //   return {
    //     ...prevWorkflow,
    //     triggers: updatedTriggers
    //   };
    // });
  };
  const removeAction = (index: number, indexPath: number[]) => {
    setWorkflow((prevWorkflow) => {
      console.log(index, indexPath, 'removing action');
  
      // Create a copy of the currentWorkflow to avoid overwriting
      let currentWorkflow = { ...prevWorkflow };
  
      // Create a reference to the parentWorkflow
      let parentWorkflow = currentWorkflow;
  
      // Traverse the nested actions based on the indexPath
      for (let i = 0; i < indexPath.length; i++) {
        const actionIndex = indexPath[i];
        if (currentWorkflow.actions[actionIndex]?.thenTriggerWorkflow) {
          parentWorkflow = currentWorkflow;
          currentWorkflow = currentWorkflow.actions[actionIndex].thenTriggerWorkflow!;
        }
      }
  
      // Remove the action from the currentWorkflow (nested workflow)
      currentWorkflow.actions.splice(index, 1);
  
      return { ...prevWorkflow };
    });
  };
  

  const renderTriggerInput = (trigger: WorkflowTriggerObject, indexPath: number[]) => {
    if (trigger.property === TriggerProperty.Todos) {
    
        if(trigger.subproperty == TriggerProperty.TaskStatus) {
          return (
            <select 
              value={trigger.input.toString()} 
              className="form-control mr-2" 
              onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)}
            >
              <option value="">Select open/closed</option>
              <option key={'open'} value={'open'}>
                  Open
                </option>
                <option key={'closed'} value={'closed'}>
                  Closed
                </option>
              
            </select>
          );
        } else if (trigger.subproperty === TriggerProperty.Priority) {
          return (
            <select 
            className="form-control mr-2" 
              value={trigger.input.toString()} 
              onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)}
            >
              <option value="">Select value</option>
                <option key={'low'} value={"low"}>
                  Low
                </option>
                <option key={'medium'} value={"medium"}>
                  Medium
                </option>
                <option key={'high'} value={"high"}>
                  High
                </option>
            </select>
            ); 
          } else if (trigger.subproperty === TriggerProperty.Category) {
            return (
              <select 
                value={trigger.input.toString()} 
                className="form-control mr-2" 
                onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)}
              >
                <option value="">Select Category</option>
                {Object.keys(TaskCategory).filter(key => isNaN(Number(key))).map(key => (
                  <option key={key} value={TaskCategory[key as keyof typeof TaskCategory]}>
                    {key}
                  </option>
                ))}
              </select>
            );
         
          } else if (trigger.subproperty === TriggerProperty.TaskEffort) {
            return (
              <select 
                value={trigger.input.toString()} 
                className="form-control mr-2" 
                onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)}
              >
                <option value="">Select effort</option>
                {Object.keys(TaskEffort).filter(key => isNaN(Number(key))).map(key => (
                  <option key={key} value={TaskEffort[key as keyof typeof TaskEffort]}>
                    {key}
                  </option>
                ))}
              </select>
            );
         
          } 

        return <></>

    } else  if (trigger.property === TriggerProperty.Type) {
      return (
        <select 
          value={trigger.input.toString()} 
          className="form-control mr-2" 
          onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)}
        >
          <option value="">Select type</option>
          {Object.keys(MessageType).filter(key => isNaN(Number(key))).map(key => (
            <option key={key} value={MessageType[key as keyof typeof MessageType]}>
              {key}
            </option>
          ))}
        </select>
      );
    } else if (trigger.property === TriggerProperty.ReplyExpected) {
        return (
          <select 
            value={trigger.input.toString()} 
            className="form-control mr-2" 
            onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)}
          >
            <option value="">Select value</option>
              <option key={'0'} value={"false"}>
                False
              </option>
              <option key={'1'} value={"true"}>
                True
              </option>
          </select>
        );
      } else {
      return (
        <input 
          type="text" 
          value={trigger.input.toString()} 
          className="form-control mr-2" 
          
          onChange={(e) => updateTrigger(indexPath, 'input', e.target.value)} 
        />
      );
    }
  };

  const updateType = (indexPath: number[], value: any) => {
    //setType(value);
    if(indexPath.length == 0)  {
      setWorkflow(prevWorklow => { 
        return {...prevWorklow, type: value }
      }); 
    } else {

    }

  };

  const updateTrigger = (indexPath: number[], field: keyof WorkflowTriggerObject, value: any) => {
    setWorkflow(prevWorkflow => {
      // Create a copy of the currentWorkflow to avoid overwriting
      let currentWorkflow = { ...prevWorkflow } as Workflow;
  
      // Create a reference to the parentWorkflow
      let parentWorkflow = currentWorkflow;
  
      // Traverse the nested actions based on the indexPath
      for (const index of indexPath) {
        if (currentWorkflow.actions[index]?.thenTriggerWorkflow) {
          parentWorkflow = currentWorkflow;
          currentWorkflow = currentWorkflow.actions[index].thenTriggerWorkflow!;
        }
      }
  
      // Update the specified trigger in the currentWorkflow using type assertions
      (currentWorkflow.triggers[indexPath[indexPath.length - 1]] as any)[field] = value;
  
      return parentWorkflow;
    });
  };
  
  
  
  const updateAction = (indexPath: number[], field: keyof WorkflowActionObject, value: any) => {
    setWorkflow((prevWorkflow) => {
      // Create a mutable copy of the workflow
      let currentWorkflow = { ...prevWorkflow };
  
      console.log("update", indexPath, workflow)
      // Create a mutable copy of the actions array
      let currentActions = [...currentWorkflow.actions];
  
      // Traverse the workflow structure according to the indexPath
      
        if (indexPath.length < 2) {
          var index = indexPath[0];
          console.log("parent workflow adction")
          // Update the target action within the currentActions array
          currentActions = currentActions.map((action, actionIndex) =>
            actionIndex === index ? { ...action, [field]: value } : action
          );
  
          // Update the actions array within the currentWorkflow
          currentWorkflow = { ...currentWorkflow, actions: currentActions };
        } else {
          var index = indexPath[0];
          // Dive into the nested workflow
          if (currentActions[index]?.thenTriggerWorkflow) {
            const nestedWorkflow = { ...currentActions[index].thenTriggerWorkflow! };
            const updatedNestedActions = nestedWorkflow.actions.map((action, actionIndex) =>
              actionIndex === indexPath[1] ? { ...action, [field]: value } : action
            );
            nestedWorkflow.actions = updatedNestedActions;
            currentActions[index].thenTriggerWorkflow = nestedWorkflow;
            currentActions = updatedNestedActions;
          }
        }
      
  
      return currentWorkflow;
    });
  };
  
  
  

  const addThenTriggerWorkflow = (actionIndex: number, type: WorkflowType) => {
    setWorkflow(prevWorkflow => {
      // Create a new array of actions, adding 'thenTriggerWorkflow' to the specified action
      const updatedActions = prevWorkflow.actions.map((action, i) => 
        i === actionIndex ? { ...action, thenTriggerWorkflow: { status: WorkflowStatus.Active, name: '', id: '', type: type, triggers: [], actions: [] } } : action
      );
  
      // Return the updated workflow object, ensuring immutability
      return {
        ...prevWorkflow,
        actions: updatedActions
      };
    });
  };

  const renderTriggers = (workflow: Workflow, indexPath: number[]) => {
    
    return workflow.triggers.map((trigger, index) => {
    
      const newPath = [...indexPath, index];

      var properties = getConditionOptions(trigger.property);
      
    return (
      <div key={index} className='input-group mt-2'>

        {index > 0 && <>and it's</>}
        <select value={trigger.property ?? ''} className="form-control mr-2" onChange={(e) => updateTrigger(newPath, 'property', parseInt(e.target.value))}>
          <option value="">Select Trigger Property</option>
          <optgroup label="Email">
          {getTriggerProperties(workflow.type).map(key => (
            <option key={key} value={key}>
              {TriggerProperty[key]}
            </option>
          ))}
          </optgroup>

        </select>
        

        {properties.length > 0 && 
          <>
          
            {trigger.property == TriggerProperty.Todos && (
              <>
                <select value={trigger.quantifier ?? ''} className="form-control mr-2" onChange={(e) => updateTrigger(newPath, 'quantifier', parseInt(e.target.value))}>
                  <option value="">Select quantifier</option>
                  {getTriggerQuantifier(trigger.property).map((option) => (
                  <option key={option} value={option}>
                    {TriggerQuantifier[option]}
                  </option>
                  ))}
                </select>
                <select value={trigger.subproperty ?? ''} className="form-control mr-2" onChange={(e) => updateTrigger(newPath, 'subproperty', parseInt(e.target.value))}>
                  <option value="">Select subproperty</option>
                  {getTriggerSubProperty(trigger.property).map((option) => (
                  <option key={option} value={option}>
                    {TriggerProperty[option]}
                  </option>
                  ))}
                </select>
              </>
            )}
            <select value={trigger.condition ?? ''} className="form-control mr-2" onChange={(e) => updateTrigger(newPath, 'condition', parseInt(e.target.value))}>
              <option value="">Select Condition</option>
              {properties.map((option) => (
                <option key={option} value={option}>
                  {TriggerCondition[option]}
                </option>
              ))}
            </select>
            {/* Conditional trigger input */}
            
            {renderTriggerInput(trigger, newPath)}
         </>
        }
        {/* Removal button */}
        <button className='btn btn-danger' onClick={() => removeTrigger(index, newPath)}><i className="bi bi-trash"></i></button>
   
      </div>
    )});
  };

  const renderActions = (workflow: Workflow, indexPath = [] as any[]) => {
    return workflow.actions.map((action, index) =>{ 

      const newPath = [...indexPath, index];

      var label = WorkflowType[workflow.type] + " actions"
      return (
      <>
        <div key={index} className='input-group mb-2'>
          {index > 0 && <>and </>}
          <select className='form-control mr-2' value={action.action ?? ''} onChange={(e) => updateAction(newPath, 'action', parseInt(e.target.value))}>
            <option value="">Select Action</option>

      
            <optgroup label={label}>

            {getActions(workflow.type).map(key => (
              <option key={key} value={key} disabled={workflow.actions.some((x) => x.action == key) }>
                {WorkflowAction[key]}
              </option>
            ))}
            </optgroup>

            <optgroup label={"Custom integrations"}>
              {getActions(WorkflowType.Integration).map(key => (
                <option key={key} value={key} disabled={workflow.actions.some((x) => x.action == key) }>
                  {WorkflowAction[key]}
                </option>
              ))}
            </optgroup>
          </select>
          {
          action.action === WorkflowAction.Slack ? (
            <SlackWidget
              action={action}
              updateAction={updateAction}
              indexPath={newPath}
            />
          ) : null}
          {
          action.action === WorkflowAction.Todoist ? (
            <TodoistWidget
              action={action}
              updateAction={updateAction}
              indexPath={newPath}
            />
          ) : null}
          {
          action.action === WorkflowAction.Triage ? (
            <TriageWidget
              action={action}
              updateAction={updateAction}
              indexPath={newPath}
            />
          ) : null}
          {
          action.action === WorkflowAction.Pager ? (
            <PagerWidget
              updateAction={updateAction}
              indexPath={newPath}
            />
          ) : null}
          {
          action.action === WorkflowAction.AddLabel ? (
            <>
            <input 
              className='form-control mr-2'
              placeholder="Use strings like '{email.type}' or '{task.category}' for dynamic variables"
              type="text" 
              value={action.input} 
              onChange={(e) => updateAction(newPath, 'input', e.target.value)} 
            />
            </>
          ) : null}
          <button className='btn btn-danger mr-2' onClick={() => removeAction(index, newPath)} disabled={action.thenTriggerWorkflow != null}><i className="bi bi-trash"></i></button>
          
          {newPath.length < 2 && 
          <button className='btn btn-secondary ' onClick={() => addThenTriggerWorkflow(index, action.action == WorkflowAction.Triage ? WorkflowType.Triage : WorkflowType.Todo)} disabled={action.action == null || action.thenTriggerWorkflow != null}>Add nested flow</button>}
          
        </div>
        {(action.thenTriggerWorkflow) && renderEditor(action.thenTriggerWorkflow, newPath)}
      </>
    )});
        
  };


  const isSubmitEnabled = () => {
    return workflow.name.length > 0 && workflow.actions.length > 0;
  };

  const addTrigger = (indexPath: number[] = []) => {
    setWorkflow((prevWorkflow) => {
      console.log(indexPath, 'adding trigger');
      
      // Create a copy of the currentWorkflow to avoid overwriting
      let currentWorkflow = { ...prevWorkflow };
      
      // Create a reference to the parentWorkflow
      let parentWorkflow = currentWorkflow;
  
      // Traverse the nested actions based on the indexPath
      for (const index of indexPath) {
        if (currentWorkflow.actions[index]?.thenTriggerWorkflow) {
          console.log("found");
          parentWorkflow = currentWorkflow;
          currentWorkflow = currentWorkflow.actions[index].thenTriggerWorkflow!;
        }
      }
  
      // Add a new trigger to the currentWorkflow (nested workflow)
      currentWorkflow.triggers.push({ property: null, subproperty: null, quantifier: null, condition: null, input: '' });
  
      return parentWorkflow;
    });
  };
  
  

  const addAction = (indexPath: number[] = []) => {
    console.log(indexPath, 'indexpat')
    setWorkflow((prevWorkflow) => {
      const updatedWorkflow = { ...prevWorkflow };
      let currentActions = updatedWorkflow.actions;
  
      // Traverse the nested actions based on the indexPath
      for (const index of indexPath) {
        if (currentActions[index]?.thenTriggerWorkflow) {
          currentActions = currentActions[index].thenTriggerWorkflow!.actions;
        } 
      }
  
      // Add a new action to the currentActions
      currentActions.push({ action: null, input: '', thenTriggerWorkflow: null });
  
      return updatedWorkflow;
    });
  };
  
  const deleteFlow = async (id: string) => {
    if(window.confirm("Sure?"))
      await workflowStore.deleteFlow(id);
    //await workflowStore.loadFlows();
  }

  const updateStatus = async (id: string, status: WorkflowStatus, until: Date | null) => {
    await workflowStore.changeStatus(id, status, until);
  }

  const setName = (val: string) => {
    setWorkflow((prevWorkflow) => {
      
      return {...prevWorkflow, name: val}
    })
  }

  const renderFlows = () => {

    if(workflowStore.loadingInitial && workflowStore.flows.length > 0)
      return <li>loading</li>;

    
  return workflowStore.flows.map((flow: Workflow, index: number) => (
    <div className='mt-4'>
      <SingleFlow key={flow.id} flow={flow} deleteFlow={deleteFlow} updateStatus={updateStatus}/>
    </div>
  ));
  }

  const handleSubmit =  async () => {
    // Prepare the data for submission
    const data = {
      workflow
    };

    if(mode)
      workflow.mode = mode;

    // Assuming createOrUpdate can handle arrays of triggers and actions
    await workflowStore.createOrUpdate(mode ? mode.id : null, data.workflow.type!, data.workflow.triggers, data.workflow.actions, data.workflow.name);
    await workflowStore.loadFlows();
  };

  const getMeaning = async (workflow: Workflow) => {
    setRuleText("Analyzing...");
    var text = await workflowStore.parseFlow(workflow);
    setRuleText(text);
  }

  const removeNestedFlow = (indexPath: number[]) => {

    var index = indexPath[0];

    setWorkflow(prevWorkflow => {
      // Create a new array of triggers, excluding the one at the specified index
      prevWorkflow.actions[0].thenTriggerWorkflow = null;
  
      // Return the updated workflow object, ensuring immutability
      return {
        ...prevWorkflow
      };
    });
  }

  const ask = async () => {
    var q = prompt("Anything important we should keep in mind while you are going into zzzz?")!;

    var flows = await workflowStore.suggestFlow(q);

console.log(flows);
  }

  // const submitLLM = (val: string) => {
  //   alert(val);
  // }

  const create = () => {
    var name = prompt("Name of mode?");
    
    if(name && name.length > 0) {
      var emoji = prompt("Emoji of mode?");
      workflowStore.createMode(name, emoji ? emoji : "");
    }
  }
  const renderEditor = (workflow: Workflow, indexPath: number[]) => {

    var cardClass = "card mt-4 ";
    if(indexPath.length > 0) 
      cardClass = "card ml-4 mt-4";

    var wTypes = Object.keys(WorkflowType).filter(key => !isNaN(Number(key)));
    if(indexPath.length === 0)
      wTypes =  Object.keys(WorkflowType).filter(key => !isNaN(Number(key)) && key == "0");

    return (
      <div className={cardClass}>
        
        <div className='card-body'>
          {indexPath.length === 0 && (
            <> 
              <div className='form-group'>
                <label>Name of workflow:</label><input type="text" className="form-control" onChange={(e) => setName(e.target.value)} />
               
                <label className='mt-4'>What focus mode is the flow part of:</label>
                {/* <div onClick={()=>ask()}>test</div> */}
                <select value={mode ? mode.id : ""} className="form-control"  onChange={(e) => setMode(workflowStore.modes.find((s) => s.id == e.target.value)!)}>
                <option value="">Select focus mode</option>
                {workflowStore.modes.map((wMode: WorkflowMode) => {
                  return (<option key={wMode.id} value={wMode.id}>
                    {wMode.label} - {wMode.name}
                  </option>);
               })}
              </select>
                <button className='mt-2 btn btn-primary btn-sm' onClick={() => create()}>Create new focus mode</button>
              </div>
            </>
          )}
          <div className='form-group'>
            {indexPath.length === 0 && <h2 className='mt-4'>1. When this happens</h2>  }
            
            <label>If a new </label>
            <select value={workflow.type ?? ''} className="form-control"  onChange={(e) => updateType(indexPath, parseInt(e.target.value))}>
                <option value="">Select workflow type</option>
                {wTypes.map(key => (
                  <option key={key} value={key}>
                    {WorkflowType[key as keyof typeof WorkflowType]}
                  </option>
                ))}
              </select>
  
            {renderTriggers(workflow, indexPath)}
            
            <center><button className='btn btn-secondary mt-4'onClick={() => addTrigger(indexPath)} disabled={workflow.triggers.some((x) => x.condition == null || x.property == null)}><i className="bi bi-node-plus-fill"></i> Add trigger</button></center>
          </div>
          <div className=' form-group'>
            {indexPath.length === 0 && <h2>2. Do this...</h2>  }
            {(workflow.actions.length > 0 || indexPath.length !== 0) && <label>then</label>}
            {renderActions(workflow, indexPath)}
            <center><button className='btn btn-secondary mt-4' onClick={() => addAction(indexPath)} disabled={workflow.actions.some((x) => x.action == null)}><i className="bi bi-node-plus-fill"></i> Add action</button></center>
            
            {indexPath.length > 0 && <button className='btn btn-danger' onClick={() => removeNestedFlow(indexPath)}>Remove nested flow</button>}
          </div>
        </div>
      </div>
    )
  }


  var currentWFMode = companyStore.users.find((x) => x.user.id == userStore.me!.id)?.currentWorkflowMode;


  return (
    <div>
      
      <h1>Automations</h1>

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <div className={" text-sm-center nav-link " + (tab == 0 ? 'active' : '')} onClick={() => setTab(0)}>Create new</div>
        </li>
        <li className="nav-item">
          <div className={" text-sm-center nav-link " + (tab == 1 ? 'active' : '')} onClick={() => setTab(1)}>Flows <span className="badge badge-secondary">{workflowStore.flows.length}</span></div>
        </li>
      </ul>

      {tab == 1 && 
      <>

        {currentWFMode && (  
          <div className='card bg-danger mt-4'>
            <div className='card-body'>
              You are currently in <b>{currentWFMode.name}</b> focus mode. This mode will toggle a set of predefined flows to optimize your productivity. If you disable/enable any flows you will step out of this mode. Just FYI
            </div>
          </div>
        )}

     
      <div className='mt-4 mb-4'>
        {renderFlows()}
      </div>
     </>}
     {tab == 0 &&
     <>
      <div className='row'>
        <div className='col'>
          {renderEditor(workflow, [])}
          <button className='btn' onClick={async () => await getMeaning(workflow)}  disabled={!isSubmitEnabled() || workflowStore.loadingInitial}>What does this rule do?</button>
        </div>
        {ruleText != null && (
        <div className='col p-4'>
          {ruleText}
        </div>
        )}
      </div>
      <button className='btn btn-primary mt-4 mb-4' onClick={handleSubmit} disabled={!isSubmitEnabled() || workflowStore.loadingInitial}>Create workflow</button>
      
     </>} 

    </div>
  );
};

export default observer(WorkflowBuilder);
