import { User } from "./user";

export enum ThreadImportStatus {
    Pending,
    Completed
}

export interface Thread {
    id: string;
   
    externalId: string;
    
    subject: string;

    tldr: string;

    status: ThreadImportStatus;
}