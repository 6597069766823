import React from "react";
import { Container, Row, Col, Input } from "reactstrap";
import Loading from "../components/Loading";
import { store, useStore } from "../stores/store";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";


export const ProfileComponent = () => {
  const { user } = useAuth0();
  const { userStore } = useStore();


  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user!.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user!.name}</h2>
          <p className="lead text-muted">{user!.email}</p>
        </Col>
      </Row>
      
    </Container>
  );
};

export default withAuthenticationRequired(observer(ProfileComponent), {
  onRedirecting: () => <Loading />,
});
