import React, { useState, useEffect } from 'react';
import { WorkflowActionObject } from '../../../models/workflow';
import { useStore } from '../../../stores/store';

interface SlackWidgetProps {
  action: WorkflowActionObject;
  updateAction: (indexPath: number[], field: keyof WorkflowActionObject, value: any) => void;
  indexPath: number[];
}

const SlackWidget: React.FC<SlackWidgetProps> = ({ action, updateAction, indexPath }) => {
  

  const  { integrationStore} = useStore();


  // Parse the input if it's a JSON string; otherwise, initialize with empty values
  const initialInput = action.input ? JSON.parse(action.input) : { channelId: '', message: '' };
  const [channelId, setChannelId] = useState(initialInput.channelId);
  const [message, setMessage] = useState(initialInput.message);

  useEffect(() => {
    // Mock API call
    const fetchDropdownOptions = async () => {
        await integrationStore.loadSetupData("slack");
    };

    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    // Update the parent component whenever the inputs change
    const jsonValue = JSON.stringify({ channelId, message });
    updateAction(indexPath, 'input', jsonValue);
  }, [channelId, message, indexPath, updateAction]);


  if(integrationStore.resyncRequired && integrationStore.resyncRequired.has("slack"))
    return <>Connect again... Something is not right</>
    

  return (
    <>
      <select 
        className='form-control mb-2'
        value={channelId} 
        onChange={(e) => setChannelId(e.target.value)}
        disabled={integrationStore.loadingInitial}
      >
        <option value="">Select an Option</option>
        {integrationStore.setupData.get("slack") && integrationStore.setupData.get("slack").channels.map((option: any) => (
          <option key={option.id} value={option.id}>{option.name}</option>
        ))}
      </select>
      <input 
        className='form-control'
        placeholder='Free text input'
        type="text" 
        value={message} 
        onChange={(e) => setMessage(e.target.value)} 
        disabled={integrationStore.loadingInitial}
      />
    </>
  );
};

export default SlackWidget;
