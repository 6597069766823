import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { TriageRequest, TriageRequestType } from "../models/triage";
export default class TriageStore {
    

    loadingInitial = false;
    request: TriageRequest | null = null;

    triageMessage = async (triageRequestId: string, result: string) => {
        await agent.Triage.triageMessage(triageRequestId, result);
        this.request!.type = TriageRequestType.Completed;
    }
    
    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    getRequest = async (triageRequestId: string) => {
            this.setLoadingInitial(true);
            try {
                this.request = await agent.Triage.get(triageRequestId);
                this.setLoadingInitial(false);
            } catch(error) {
                this.setLoadingInitial(false);
            }
    }
    
    constructor() {
        makeAutoObservable(this);
    }
}