import React from 'react';

interface ProgressBarProps {
  value: number; // Value should be between 0 and 100
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  // Ensure value is within the range [0, 100]
  const normalizedValue = Math.min(Math.max(value, 0), 100);

  return (
    <div style={{
      width: '100%', // Full width of the container
      backgroundColor: '#eee', // Background color of the progress bar
      borderRadius: '5px', // Optional: Rounded corners for the progress bar
    }}>
      <div style={{
        width: `${normalizedValue}%`, // Width of the filled portion based on the value
        backgroundColor: normalizedValue < 60 ? 'tomato' : 'blue', // Color of the filled portion
        height: '8px', // Height of the progress bar
        borderRadius: '4px', // Keep consistent with the container for rounded corners
      }}>
      </div>
    </div>
  );
};

export default ProgressBar;
