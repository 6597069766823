import { useEffect } from 'react';
import history from "../utils/history";  
import React from 'react';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';


const CallbackProcess = () => {

    const  { integrationStore} = useStore();

    const navigate = useNavigate();

    const sendCodeToBackend = async (code: any, app: string) => {
        // Implement the API call to your backend
        // with the authorization code
        console.log(code)
        await integrationStore.add(app, code);
    };

    useEffect(() => {
        // Assume logic to extract companyid from the URL if needed
        const test = async () => {
            const code = sessionStorage.getItem('tempAuthCode');
            const app = sessionStorage.getItem('tempApp'); // Retrieve the app if needed
            if (code && app) {
                await sendCodeToBackend(code, app); // Adjust according to your actual function parameters
                // Cleanup after processing
                sessionStorage.removeItem('tempAuthCode');
                sessionStorage.removeItem('tempApp');

                window.location.href = window.location.origin;
            }
        }

        test()
    }, []);
    

    // Render something or just a loader
    return <>Setting it up apps...</>;
};

export default withAuthenticationRequired(observer(CallbackProcess), {
    onRedirecting: () => <Loading />,
  }); 