import React, { useState, useEffect } from 'react';
import { WorkflowActionObject } from '../../../models/workflow';
import { useStore } from '../../../stores/store';

interface TodoistWidgetProps {
  action: WorkflowActionObject;
  updateAction: (indexPath: number[], field: keyof WorkflowActionObject, value: any) => void;
  indexPath: number[];
}

const TodoistWidget: React.FC<TodoistWidgetProps> = ({ action, updateAction, indexPath }) => {
  

  const  { integrationStore} = useStore();


  // Parse the input if it's a JSON string; otherwise, initialize with empty values
  const initialInput = action.input ? JSON.parse(action.input) : { projectId: '', message: '' };
  const [focusModeInclude, setFocusModeInclude] = useState(false);
  const [importance, setImportance] = useState(initialInput.projectId);
  const [message, setMessage] = useState(initialInput.message);

  useEffect(() => {
    // Mock API call
    // const fetchDropdownOptions = async () => {
    //     await integrationStore.loadSetupData("todoist");
    // };

    // fetchDropdownOptions();
  }, []);

  useEffect(() => {
    // Update the parent component whenever the inputs change
    const jsonValue = JSON.stringify({ importance, message, focusModeInclude });
    updateAction(indexPath, 'input', jsonValue);
  }, [importance, message, indexPath, updateAction]);

  // if(integrationStore.resyncRequired && integrationStore.resyncRequired.has("todoist"))
  //   return <>Connect again... Something is not right</>

  return (
    <>
    <input type="checkbox" checked={focusModeInclude} onChange={(e:  React.ChangeEvent<HTMLInputElement>) => setFocusModeInclude(e.target.checked)}></input>Include my focus mode:
      Reply to sender the following message:
      <textarea
        onChange={(e) => setMessage(e.target.value)}
        className='form-control mb-2'></textarea>
        
      Give option to rank the important by: 
      <select 
        className='form-control mb-2'
        value={importance} 
        onChange={(e) => setImportance(e.target.value)}
        disabled={integrationStore.loadingInitial}
      >
        <option value="">Select</option>
        <option key={1} value={0}>Score (0-10)</option>
        <option key={2} value={1}>Yes/No</option>
      </select>
     
    </>
  );
};

export default TodoistWidget;
