import { formatRelative, subDays } from "date-fns";

export const formatRelativeTime = (dateString: string) => {

    if(!dateString) return "";
console.log(dateString);
    const date = new Date(dateString);
    const now = new Date();

    // Check if the time is exactly at 00:00:00
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
        return formatRelative(subDays(date, 1), now);
    }

    return formatRelative(date, now);
}