import React, { useState } from 'react';
import Avatar from '../Avatar';
import { formatRelativeTime } from '../../utils/dateUtils';
import { TriggerCondition, TriggerProperty, TriggerQuantifier, Workflow, WorkflowAction, WorkflowStatus, WorkflowType } from '../../models/workflow';
import { observer } from 'mobx-react-lite';
import UpdateStatusModal from './UpdateStatusModal';

interface FlowProps {
  flow: Workflow;
  deleteFlow?: (id: string) => Promise<void>;
  updateStatus?: (id: string, status: WorkflowStatus, until: Date | null) => Promise<void>;
}

const SingleFlow: React.FC<FlowProps> = ({ flow, deleteFlow = null, updateStatus = null }) => {
 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderFlowDescription = (flow: Workflow): string => {
    let triggerDescriptions = `if a new ${WorkflowType[flow.type].toLowerCase()} is handled`; // Default trigger description

    if (flow.triggers && flow.triggers.length > 0) {
      triggerDescriptions = "If " + flow.triggers.map(trigger => {
        const property = `<span class='badge badge-secondary'>${TriggerProperty[trigger.property!]}</span>`;
        const quantifier = trigger.quantifier ? `${TriggerQuantifier[trigger.quantifier!]}` : "";
        const subproperty = trigger.subproperty ? `<span class='badge badge-secondary'>${quantifier} ${TriggerProperty[trigger.subproperty!]}</span>` : "";
        const condition = `<span class='badge badge-light'>${TriggerCondition[trigger.condition!]}</span>`;
        const input = trigger.input;
        return `an email's ${property} ${subproperty} ${condition} '${input}'`;
      }).join(" and ");
    }

    const actionDescriptions: string = flow.actions?.map(action => {
      const actionType = `<span class='badge badge-info'>${WorkflowAction[action.action!]}</span>`;
      const actionInput = action.input;
      let actionDescription = `${actionType}`;
      if (actionInput) {
        actionDescription += ` with '${actionInput}'`;
      }
      if (action.thenTriggerWorkflow) {
        actionDescription += ` and then ${renderFlowDescription(action.thenTriggerWorkflow)}`;
      }
      return actionDescription;
    }).join(" and ") || ''; // Provide a default empty string

    return `${triggerDescriptions}, then ${actionDescriptions}.`;
  };

  return (
    <div className='card'>
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <b>{flow.name} {flow.mode && <span className='badge badge-secondary'>{flow.mode.label} {flow.mode.name}</span>}</b>
        </div>
        {flow.status == WorkflowStatus.Paused && updateStatus && (
          <div><span className="badge badge-danger" onClick={() => setIsModalOpen(true)}>Undeployed</span></div>
          
        )}  
        <UpdateStatusModal
        isOpen={isModalOpen}
        mode={flow?.name}
        onClose={() => setIsModalOpen(false)}
        onUpdate={(until: Date | null) => {
          if (updateStatus) {
            updateStatus(flow.id, WorkflowStatus.Active, until);
          }
          setIsModalOpen(false);
        }}
      />
        {flow.status == WorkflowStatus.Active && updateStatus && (
          <div><span className="badge badge-success" onClick={async () => await updateStatus(flow.id, WorkflowStatus.Paused, null)}>Deployed {flow.until && <>until {formatRelativeTime(flow.until!)}</>}</span></div>
        )}
        
        {deleteFlow && <i className="bi bi-trash" onClick={async () => await deleteFlow(flow.id)}></i>}
      </div>
      <div className='card-body' dangerouslySetInnerHTML={{ __html: renderFlowDescription(flow) }}>
      </div>
      <div className='card-footer'>
        <small>Executed {flow.noOfExecutions} times - Created by <Avatar user={flow.user!} showEmail={false} showName={false} /> {formatRelativeTime(flow.created!)}</small>
      </div>
    </div>
  );
};

export default observer(SingleFlow);
