import React, { useState, useEffect } from 'react';
import { useStore } from '../../stores/store';
import { WorkflowActionObject } from '../../models/workflow';
import Gauge from '../Gauge';
import ProgressBar from '../ProgressBar';
import { Score } from '../../models/score';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Line } from 'react-chartjs-2';

import { Col, Container, Row } from 'reactstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface HistoricalDataPoint {
  date: string;
  value: number;
}

interface AggregatedScore {
  name: string;
  score: number; // Assuming this is already a percentage
  label?: string;
  url?: string;
}

const ScoreWidget: React.FC = ({ }) => {
  

  const { scoreUrl, date } = useParams<{ scoreUrl: string, date: string }>();

  const navigate = useNavigate();
  const  { scoreStore , userStore } = useStore();

  useEffect(() => {
    const fetchScores = async () => {
      try {
        // Assume listScores fetches all scores, you might need to adjust this
        // to ensure it fetches scores relevant to the selected date as well
        await scoreStore.listScores();
        console.log("Scores loaded:", scoreStore.scores?.length);
      } catch (error) {
        console.error('Failed to load scores:', error);
      }
    };
  
    fetchScores();
  }, [scoreStore]);
  
  if (scoreStore.loadingInitial || !scoreStore.scores.length) return <>...</>;

  // Filtering logic
  const selectedDateGroup = scoreStore.scores.find(group => group.dateTime.startsWith(date!));
  const mainScore = selectedDateGroup?.scores.find(s => s.url === scoreUrl);
  
  // Assuming mainScore is defined as before

// Function to aggregate scores by URL, returning averaged score values
const aggregateSubScoresByUrl = (scores: Score[]): Score[] => {
  const scoreMap = scores.reduce((acc: Record<string, { score: number; count: number; scoreObj: Score }>, score) => {
    if (!acc[score.url]) {
      acc[score.url] = { score: score.score, count: 1, scoreObj: score };
    } else {
      acc[score.url].score += score.score;
      acc[score.url].count += 1;
    }
    return acc;
  }, {});

  return Object.values(scoreMap).map(({ score, count, scoreObj }) => ({
    ...scoreObj,
    score: score / count, // Average score
  }));
};

// Use this function to get aggregated subScores
const subScores = aggregateSubScoresByUrl(selectedDateGroup?.scores.filter(s => s.parentScoreType === mainScore?.scoreType) || []);


  if (!mainScore || !subScores) return <>No scores found for this date.</>;

  var s = Math.round(100 * mainScore.score);
// Extract the score's historical trend data
const aggregateScoresByDay = (): HistoricalDataPoint[] => {
  const scoresByDay = scoreStore.scores.flatMap(groupedScore =>
    groupedScore.scores
      .filter(score => score.url === scoreUrl && score.parentScoreType == null)
      .map(score => ({
        date: groupedScore.dateTime.split('T')[0],
        value: score.score, // Keep as is, assuming these are already in the correct scale
      }))
  ).reduce((acc: { [key: string]: { total: number, count: number } }, { date, value }) => {
    if (!acc[date]) acc[date] = { total: 0, count: 0 };
    acc[date].total += value;
    acc[date].count += 1;
    return acc;
  }, {});

  // Calculate the average for each day
  return Object.entries(scoresByDay).map(([date, { total, count }]) => ({
    date,
    value: (total / count) * 100, // Now calculating the average and ensuring it's scaled as a percentage
  }));
};



const historicalData = aggregateScoresByDay().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
// Generate random data for the second line
const randomData = historicalData.map(() => Math.floor(Math.random() * 100));


const data = {
  labels: historicalData.map(item => item.date),
  datasets: [
    {
      label:'Yours',
      data: historicalData.map(item => item.value),
      fill: true, // Enable filling below the line
      backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill color
      borderColor: 'rgb(75, 192, 192)', // Line color
      tension: 0.4, // Smoothing the line
    }
    // {
    //   label: 'Your collegues',
    //   data: randomData,
    //   fill: false, // Adjust based on your preference
    //   borderColor: 'rgb(255, 205, 86)', // Yellow color for the second line
    //   tension: 0.4, // Smoothing the line
    // }
  ],
};
const options = {
  responsive: true,
  scales: {
    y: { // Depending on the version of Chart.js, this might be 'yAxes' for versions < 3
      min: 0, // Set minimum value
      max: 100, // Set maximum value
      ticks: {
        // Optional: Adjust the step size or other tick properties if needed
        stepSize: 10,
      }
    }
  },
  // Additional options for the appearance and behavior of the chart can be added here
};


  return (
    <Container className="mb-5">
       <Row className="align-items-center profile-header mb-5 text-center text-md-left">
         <Col md>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href={`/`}>Home</a></li>
              <li className="breadcrumb-item"><a onClick={() => navigate(`/${userStore.me!.lastCompanyId}/activity`)}>Activity</a></li>
              <li className="breadcrumb-item active" aria-current="page">{mainScore.name}</li>
              <li className="breadcrumb-item active" aria-current="page">{date}</li>
            </ol>
          </nav>
           <h1 className="mb-4">{mainScore.name}</h1>
            <p>{mainScore.description}</p>
            <h4 className="mt-4">{s} <small>{s < 40 ? 'Poor' : (s > 70 ? 'Excellent' : 'Good')}</small></h4>
            <div className="card">
              <div className="card-body">
                <Line data={data} options={options} />
                </div>
              </div>
              
                <div className="container mt-4"  style={{padding: 0}}>
                  <h4>{mainScore.name} contributors</h4>
                  {subScores.map((subscore: any) => {
                    var s2 = Math.round(100 * subscore.score)
                    return (
                      <div className="card mb-2">
                        <div className="card-body">
                        <div className="row">
                          <div className="col float-start">
                            <small><b>{subscore.name}</b></small><br/>
                          </div>
                          <div className="col" style={{textAlign: 'right'}}>
                            <small><b>{subscore.label}</b></small>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <ProgressBar value={s2} />
                            <small>{subscore.description}</small>
                          </div>
                        </div>
                        </div>
                        </div>
                    )
                  })}
            </div>
          </Col>
        </Row>
      </Container>
  );
};

export default observer(ScoreWidget);
