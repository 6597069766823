import { useEffect } from 'react';
import history from "../utils/history";  
import React from 'react';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';


const Callback = () => {

    const  { integrationStore} = useStore();

    const sendCodeToBackend = async (code: any, app: string) => {
        // Implement the API call to your backend
        // with the authorization code
        console.log(code)
        await integrationStore.add(app, code);
    };

    
    useEffect(() => {
        const handleAuthCode = async () => {

            
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const stateEncoded = urlParams.get('state');



            if (code) {
                // Now you have the authorization code
                // You can send it to your backend
                if (stateEncoded) {
            
                    // Decode the state parameter
                    const stateDecoded = JSON.parse(decodeURIComponent(stateEncoded));
                    const { app, companyid } = stateDecoded;

                    // Temporarily store the code and possibly the app for retrieval after redirect
                    sessionStorage.setItem('tempAuthCode', code);
                    sessionStorage.setItem('tempApp', app); 
                    

                    // Redirect to a tenant-specific URL that includes the companyid
                    const tenantSpecificPath = `/${companyid}/callback`;
                    history.push(tenantSpecificPath);
                    
                    // Now use the appName and code to communicate with your backend
                  }
                // // Redirect to another page after handling the callback
                // console.log("redit");
                // history.push('/apps');
                // window.location.reload();
            } else {
                // Handle the absence of an authorization code
                // Redirect or display an error
                // Redirect or error handling code here
            }
        };

        handleAuthCode();
    }, [history]);


    // Render something or just a loader
    return <>Setting it up apps...</>;
};

export default withAuthenticationRequired(observer(Callback), {
    onRedirecting: () => <Loading />,
  }); 