import React, { useEffect, useState } from 'react';

const ProcessingIndicator: React.FC = () => {
  const [text, setText] = useState('Extracting tasks...');

  useEffect(() => {
    // Text updates
    const texts = ['Extracting tasks...', 'Processing data...', 'Loading information...', 'Finalizing...'];
    let index = 0;

    // Interval to change text every 5 seconds
    const interval = setInterval(() => {
      index = (index + 1) % texts.length;
      setText(texts[index]);
    }, 5000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  return <div>{text}</div>;
};

export default ProcessingIndicator;
