import { User } from "./user";
import { WorkflowMode } from "./workflowMode";

export enum UserCompanyRole {
    Viewer,
    Admin,
    Owner
}

export interface UserCompany {
    user: User,
    currentWorkflowMode?: WorkflowMode | null,
    role: UserCompanyRole
}