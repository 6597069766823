import React, { useEffect, useState } from "react";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import { WorkflowMode } from "../models/workflowMode";
import UpdateStatusModal from "../components/Workflow/UpdateStatusModal";
import { formatRelativeTime } from "../utils/dateUtils";
import ScoreModal from "../components/Scores/ScoreModal";
import { FocusModeSwitchResponse, FocusSwitchSummaryResponse } from "../models/foucsModeResponse";


const Start: React.FC = () => {
  const { userStore , workflowStore, scoreStore, modalStore, companyStore } = useStore();

  
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowMode, setWorkflowMode] = useState<string>("");

  const [modeSwitchResponse, setModeSwitchResponse] = useState<FocusModeSwitchResponse | null | undefined>(null);
  const [previousModeStartTimestamp, setPreviousModeStartTimestamp] = useState<string | null>(null);
  

  // When you want to close the modal
  const closeScoreModal = () => {
    setIsScoreModalOpen(false);
  };
  
  const renderButtonLabels = () => {

    if(userStore.loadingInitial)
        return <small>Working...</small>

    return (<>
      {currentWFMode && currentWFMode.until && <small>Active until {formatRelativeTime(currentWFMode.until!)}</small>}
      {currentWFMode && <span onClick={() => setMode(null, null)}><small> - Clear</small></span>}
    </>);
  }

  const setMode = async (mode: WorkflowMode | null, until: Date | null)  => {
    var focusModeSwitch = await userStore.setFocusMode(mode, until);
    setModeSwitchResponse(focusModeSwitch);
    await scoreStore.listScores(focusModeSwitch?.previousModeStartTimestamp);
    await companyStore.getUsers();
    if(focusModeSwitch != null && focusModeSwitch.previousModeStartTimestamp != null)
      setPreviousModeStartTimestamp(focusModeSwitch!.previousModeStartTimestamp!);

    setIsScoreModalOpen(focusModeSwitch!.summary != null);
    // if(!userStore.me?.isInputWatched) {
    //   if(mode != null && window.confirm("Start watch?"))
    //     await watch(true);
    // }
  }

  var currentWFMode = companyStore.users.find((x) => x.user.id == userStore.me!.id)?.currentWorkflowMode;

  return (
  <>
   


    {userStore.me?.isOnboarded && (
      <>
        <div className="mt-2 center">
          <div className="inline-elements">
              <select disabled={userStore.loadingInitial} value={currentWFMode ? currentWFMode.id : ''} className="form-control form-select-sm"  onChange={(e) => { setWorkflowMode(e.target.value); setIsModalOpen(true) } } >
                <option value="">Select focus mode</option>
                {workflowStore.modes.map((wMode: WorkflowMode) => {
                  return (<option key={wMode.id} value={wMode.id}>
                    {wMode.label} {wMode.name}
                  </option>);
                })}
              </select>      

       
          </div>
          {renderButtonLabels()}
          {userStore.reccomnedations && userStore.reccomnedations.map((recommendation) => {
            return (<div>{recommendation.reason}<br/><small>{recommendation.suggestedAction}</small></div>);
          })}
          {modeSwitchResponse != null && <ScoreModal isOpen={isScoreModalOpen} onClose={closeScoreModal} switchResponse={modeSwitchResponse} date={previousModeStartTimestamp!} />}
        <UpdateStatusModal
            isOpen={isModalOpen}
            mode={workflowStore.modes.find((s) => s.id == workflowMode)?.name}
            onClose={() => setIsModalOpen(false)}
            onUpdate={async (until: Date | null) => {
              modalStore.openModal("Generating summary report..."); 
              await setMode(workflowStore.modes.find((s) => s.id == workflowMode)!, until);
              setIsModalOpen(false);
              modalStore.closeModal(); 
            }}
          />
        </div>
      </>
      )}
           </>
  );
};

export default observer(Start);
