import React from 'react';
import { Task, TaskStatus, TaskPriority, TaskCategory, MessageType, TaskEffort } from '../../models/task';
import { Link } from 'react-router-dom';
import { formatRelativeTime } from '../../utils/dateUtils';
import Avatar from '../Avatar';

type TaskRowProps = {
  task: Task;
  selectTask: (task: Task) => void;  
  reassign: (task: Task) => void;
  taskStatus: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
};

const getCategoryClass = (category: TaskCategory) => {
  switch(category) {
    case TaskCategory.Action: 
    return "badge bg-primary";

    case TaskCategory.Assistance: 
    return "badge bg-primary";

    case TaskCategory.Confirmation: 
    return "badge bg-primary";

    case TaskCategory.Decision: 
    return "badge bg-primary";

    case TaskCategory.Feedback: 
    return "badge bg-primary";

    case TaskCategory.Information: 
    return "badge bg-primary";

    case TaskCategory.Introduction: 
    return "badge bg-primary";

    case TaskCategory.Meeting: 
    return "badge bg-primary";

    case TaskCategory.Opportunity: 
    return "badge bg-primary";

    case TaskCategory.Payment: 
    return "badge bg-primary";

    case TaskCategory.Proposal: 
    return "badge bg-primary";

    case TaskCategory.Reminder: 
    return "badge bg-primary";

    case TaskCategory.Review: 
    return "badge bg-primary";

    case TaskCategory.Submission: 
    return "badge bg-primary";

    case TaskCategory.Undefined: 
    return "badge bg-primary";

    case TaskCategory.Update: 
    return "badge bg-primary";

    default:  
    return "badge bg-secondary";
  }
}

const renderPriority = (priority: TaskPriority) => {
  switch (priority) {
    case TaskPriority.High:
      return "High";
    case TaskPriority.Medium:
      return "Medium";
    case TaskPriority.Low:
      return "Low";

    default: 
      return "";
  }
}

const renderPriorityClass = (priority: TaskPriority) => {
  switch (priority) {
    case TaskPriority.High:
      return "badge-high";
    case TaskPriority.Medium:
      return "badge-medium";
    case TaskPriority.Low:
      return "badge-low";
    default: 
      return "badge-secondary"; // Default case for undefined priority
  }
}

const renderEffort = (priority: TaskEffort) => {
  switch (priority) {
    case TaskEffort.High:
      return "* * *";
    case TaskEffort.Medium:
      return "* *";
    case TaskEffort.Low:
      return "*";

    default: 
      return "";
  }
}

const renderEffortClass = (priority: TaskEffort) => {
  switch (priority) {
    case TaskEffort.High:
      return "badge-high";
    case TaskEffort.Medium:
      return "badge-medium";
    case TaskEffort.Low:
      return "badge-low";
    default: 
      return "badge-secondary"; // Default case for undefined priority
  }
}


const TaskRow: React.FC<TaskRowProps> = ({
  task,
  selectTask,
  reassign,
  taskStatus
}) => {
  return (
    <div className={`card mb-3 ${task.status === TaskStatus.Completed ? 'task-completed' : ''}`}>
    <div className="card-header d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center pl-3">
        <input
          className="form-check-input me-2 big-checkbox" // Added class for bigger checkbox
          type="checkbox"
          checked={task.status === TaskStatus.Completed}
          onChange={(event) => taskStatus(event, task.id)}
        />
        <h5 className="card-title mb-0 ml-2">{task.title}</h5>
      </div>
      <span className={`badge ${renderPriorityClass(task.priority)}`}>Priority: {renderPriority(task.priority)}</span>
      <span className={`badge ${renderEffortClass(task.effort)}`}>Effort: {renderEffort(task.effort)}</span>
    </div>
      <div className='card-body'>
        <div className="row align-items-center">
          
          <div className="col">
            <p className="card-text">
              <small className="text-muted">
                Email is a {MessageType[task.message.type]} and task is of type {TaskCategory[task.category]}
                {task.dueDate && <span className="badge bg-warning text-dark">Due: {formatRelativeTime(task.dueDate)}</span>}
              </small>
              <br />
              <small>
                <Link to={`/threads/${task.thread.id}`}>{task.thread.subject}</Link> - {MessageType[task.message.type]} email
              </small>

              TLDR: {task.thread.tldr}
            </p>
          </div>
        </div>
      </div>

      <div className="card-footer text-end">
        <small>Assigned to: <Avatar user={task.user} /> <span className='badge badge-info' onClick={() => reassign(task)}>Reassign task...</span></small>
      </div>
    </div>
  );
};

export default TaskRow;
