import React, { Fragment, useEffect, useState } from "react";
import { Task, TaskStatus } from "../models/task";
import { useStore } from "../stores/store";
import { GroupedTasks } from "../components/GroupedTask";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";
import { TodaysTasks } from "../components/TodaysTask";
import Tasks from "./Tasks";

export interface TasksGroupedByThread {
  [key: string]: Task[];
}

const Home: React.FC = () => {
  const { userStore , taskStore, modalStore } = useStore();
  const [groupedTasks, setGroupedTasks] = useState<TasksGroupedByThread>({});

  useEffect(() => {
    const fetch = async () => {
      try {
        await taskStore.loadTasks(true);
        const dispose = autorun(() => {
          const grouped = groupTasksByThreadId(taskStore.tasks as Task[]);
          setGroupedTasks(grouped);
        });
      
        // Cleanup function to dispose of autorun when component unmounts
        return () => dispose();
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetch();
  }, [taskStore]);


  const taskStatusChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const isChecked = e.target.checked;
    
    changeStatus(id, isChecked);
  };

  const changeStatus = async (id: string, isChecked: boolean) => {
    await taskStore.setStatus(id, isChecked ? TaskStatus.Completed : TaskStatus.Pending, "");
  }
  
  const isOverdue = (dueDate: string | undefined): boolean => {
    return dueDate ? new Date(dueDate) < new Date() : false;
  };

  const groupTasksByThreadId = (tasks: Task[]): TasksGroupedByThread => {
    // First, group the tasks by threadId
    const grouped = tasks.reduce((groupedTasks: TasksGroupedByThread, task: Task) => {
      const threadId = task.thread.id || 'noThread';
      if (!groupedTasks[threadId]) {
        groupedTasks[threadId] = [];
      }
      groupedTasks[threadId].push(task);
      return groupedTasks;
    }, {});
  
    // Then, sort each group by dueDate and priority
    Object.keys(grouped).forEach(threadId => {
      if (grouped[threadId].every(task => task.status === TaskStatus.Completed)) {
        delete grouped[threadId];
      } else {
        grouped[threadId].sort((a, b) => {
          const overdueA = isOverdue(a.dueDate);
          const overdueB = isOverdue(b.dueDate);
      
          if (overdueA && !overdueB) {
            return -1; // A is overdue, B is not
          } else if (!overdueA && overdueB) {
            return 1; // B is overdue, A is not
          }
      
          // If both are overdue or neither is overdue, sort by due date
          const dateA = a.dueDate ? new Date(a.dueDate).getTime() : Number.MAX_VALUE;
          const dateB = b.dueDate ? new Date(b.dueDate).getTime() : Number.MAX_VALUE;
      
          if (dateA !== dateB) {
            return dateA - dateB;
          }
      
          // If due dates are equal, sort by priority (higher priority first)
          return b.priority - a.priority;
        });
      }
    });    
  
    return grouped;
  };
  
  const [tab, setTab] = useState<number>(1);
  
  return (
    <Fragment>
      <h1>My feed</h1>
      <ul className="nav nav-tabs mt-4">
        <li className="nav-item">
          <div className={" text-sm-center nav-link " + (tab == 1 ? 'active' : '')} onClick={() => setTab(1)}>What is the plan for today?</div>
        </li>
        <li className="nav-item">
          <div className={" text-sm-center nav-link " + (tab == 2 ? 'active' : '')} onClick={() => setTab(2)}>Full list (temp)</div>
        </li>
        <li className="nav-item">
          <div className={" text-sm-center nav-link " + (tab == 3 ? 'active' : '')} onClick={() => setTab(3)}>Todos (temp)</div>
        </li>
      </ul>

      <div className="p-4">
        {tab == 3 && (
          <Tasks/>
        )}
        {tab == 2 && (
          <>
            {Object.keys(groupedTasks).length > 0 ? (
              <>
                <b>Since we saw you last</b><br/>
                <GroupedTasks taskStatus={taskStatusChangeHandler} tasksGroupedByThread={groupedTasks} />
              </>
            ) : (
              <>
              <b>Nothing quite yet :(</b><br/>
                Here we will summarize and prioritize what you should work on
              </>
            )}
          </>
        )}

        {tab == 1 && (
          <>
            {Object.keys(groupedTasks).length > 0 ? (
              <>
              <TodaysTasks tasks={taskStore.tasks as Task[]} taskStatusChange={taskStatusChangeHandler} />

              </>
            ) : (
              <>
              <b>Nothing quite yet :(</b><br/>
                Here we will summarize and prioritize what you should work on
              </>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default observer(Home);
