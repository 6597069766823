import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import ProgressBar from "../components/ProgressBar";
import { Line } from 'react-chartjs-2';


import { useNavigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Score } from "../models/score";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



interface AggregatedScore {
  name: string;
  score: number;
  label: string;
  url: string;
}

export interface ScoreAccumulator {
  [key: string]: {
    total: number;
    count: number;
    name: string;
    label: string;
    url: string;
  };
}

const PerformanceComponent = () => {
  const  { logStore, scoreStore, userStore } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      try {
        await logStore.loadLogs();
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetch(); 

  }, []);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        
        await scoreStore.listScores();
      console.log("scores", scoreStore.scores?.length);
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetchScores(); 

    const setToken = async () => {
      await scoreStore.listReflectionLogs(selectedDate!);
    }

    setToken();

  }, [scoreStore]);
  


  const move = (url: string, date: string) => {
    navigate(`/${userStore.me?.lastCompanyId}/scores/${url}/${date}`);
  }


  const setSelectedDateWrapper = async (val: string) => {
    
    setSelectedDate(val);
    await scoreStore.listReflectionLogs(val!);
  }
  
  const [selectedDate, setSelectedDate] = useState<string | null>(new Date().toISOString().split('T')[0]);

  const uniqueDates = Array.from(new Set(scoreStore.scores.map(score => score.dateTime.split('T')[0])));

  // Assuming scoreStore.scores is your GroupedScore array
  const { scores } = scoreStore;

  if(logStore.logs == null)
   return <>loading....</>

  const prepareChartData = () => {
    const scoresByDay = scoreStore.scores.reduce((acc: any, { dateTime, scores }) => {
      const date = dateTime.split('T')[0];
      scores.filter(score => score.parentScoreType == null).forEach(score => {
        if (!acc[date]) acc[date] = { total: 0, count: 0 };
        acc[date].total += score.score;
        acc[date].count += 1;
      });
      return acc;
    }, {});
  
    return Object.entries(scoresByDay).map(([date, { total, count } ] : any) => ({
      date,
      averageScore: total / count,
    })).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };
  
  const historicalData = prepareChartData();
  // Generate random data for the second line
const randomData = historicalData.map(() => Math.floor(Math.random() * 100));


const chartData = {
  labels: historicalData.map(item => item.date),
  datasets: [
    {
      label: 'Your',
      data: historicalData.map(item => item.averageScore * 100),
      fill: true,
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.4,
    },
    // {
    //   label: 'Your collegues',
    //   data: randomData,
    //   fill: false, // Adjust based on your preference
    //   borderColor: 'rgb(255, 205, 86)', // Yellow color for the second line
    //   tension: 0.4, // Smoothing the line
    // },
  ],
};

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      min: 0,
      max: 100,
    },
  },
  onClick: (e: any, element:any) => {
    if (element.length > 0) {
      const index = element[0].index;
      const date = historicalData[index].date;
      setSelectedDateWrapper(date); // Assuming setSelectedDate updates the state and triggers a re-render
    }
  },
};

// Default selectedDate to today if not set
const today = new Date().toISOString().split('T')[0];
const displayDate = selectedDate || today;
// Assuming displayedScores is an array of { name, score, ... } objects for the selected date

const displayedScores = scoreStore.scores
  .filter(group => group.dateTime.startsWith(displayDate))
  .flatMap(group => group.scores as Score[])
  .filter(score => score.parentScoreType == null)
  .reduce((acc: ScoreAccumulator, score) => {
    if (!acc[score.scoreType]) {
      acc[score.scoreType] = { total: score.score, count: 1, name: score.name, label: score.label, url: score.url };
    } else {
      acc[score.scoreType].total += score.score;
      acc[score.scoreType].count += 1;
    }
    return acc;
  }, {});

const aggregatedScores: AggregatedScore[] = Object.values(displayedScores).map(({ total, count, name, label, url }) => ({
  name,
  score: Math.min(100, (total / count) * 100), // Cap the score at 100
  label,
  url
}));


const renderGraphAndInfo = () => {

  if(scoreStore.scores.length == 0)
    return <div>
      We are still measuring... Check back later
      </div>

  return <div>
  <select onChange={(e) => setSelectedDateWrapper(e.target.value)} value={selectedDate || ''} className="form-control mb-2">
   <option value="">Select a Date</option>
   {uniqueDates.map((date, index) => (
     <option key={index} value={date}>
       {date} {/* Displaying just the date */}
     </option>
   ))}
 </select>

  <div className="card">
   <div className="card-body">
   <Line data={chartData} options={chartOptions} />
     <h4 className="mt-4">{totalAverageScore.toFixed(0)}<small className="ml-4">Good</small></h4>
     <b className="mt-4">Productivity Score <i className="bi bi-info-circle" onClick={() => 
     alert("Your Producitivity Score, is a comprehensive productivity metric that encapsulates your overall email management performance, combining insights from Efficiency, Effectiveness, Organization, Engagement, Automation, Distraction, Focus, and Network Size. It serves as a holistic indicator of how well you leverage email as a tool for business communication, collaboration, and time management. A higher Productivity Score signifies that you are maximizing the potential of your email interactions to support and enhance your business operations. Improving this score can lead to significant benefits for your business, including streamlined workflows, stronger professional relationships, more effective communication, and a greater ability to focus on strategic priorities. By focusing on optimizing each component of the Productivity Score, you can unlock higher levels of efficiency and effectiveness in your work, contributing to the overall success and competitiveness of your organization. This score is not just a reflection of how you manage your inbox but an essential gauge of your capacity to drive business forward in an increasingly digital world.")}></i></b>
     <p className="mt-4">Reflections:{' '} 
       {scoreStore.reflectionLogs.map((x) => {
         return <div className="badge badge-dark mr-2">{x.reflection.name} {x.count > 1 ? ` | ${x.count}` : ``}</div>
       })}
     </p>
   </div>
   <div>
     
   </div>
 </div>
  <div className=" mt-2">
  {
aggregatedScores.length > 0 ? (
aggregatedScores.map((score: AggregatedScore, i: number) => (   
<div className="card mb-2">   
<div className="card-body">
 <div className="container" style={{padding: 0}} onClick={() => move(score.url, selectedDate!)}>
     <div className="row">
       <div className="col float-start">
         <small>{score.name}</small>
       </div>
       <div className="col" style={{textAlign: 'right'}}>
         <small>{score.label}</small>
       </div>
     </div>
     <div className="row mb-2">
       <div className="col">
         <ProgressBar value={score.score} />
       </div>
     </div>
   </div>
 </div>
</div>
)
)): (<>no thing</>)}
</div>
</div>
}

// Now, use displayedScores in the render method to display progress bars
const totalAverageScore = aggregatedScores.reduce((acc, { score }) => acc + score, 0) / aggregatedScores.length;


   return (
     <Container className="mb-5">
       <Row className="align-items-center profile-header mb-5 text-center text-md-left">
         <Col md>
           <h1 className="mb-4">Performance</h1>

           {renderGraphAndInfo()}          

         </Col>
       </Row>
     </Container>
   );
};

export default withAuthenticationRequired(observer(PerformanceComponent), {
  onRedirecting: () => <Loading />,
});


