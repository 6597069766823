import React from "react";
import { TasksGroupedByThread } from "../views/Home";
import { formatRelativeTime } from "../utils/dateUtils";
import { TaskCategory, TaskPriority, TaskStatus } from "../models/task";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";


export const renderPriority = (priority: TaskPriority) => {
    switch (priority) {
      case TaskPriority.High:
        return "High";
      case TaskPriority.Medium:
        return "Medium";
      case TaskPriority.Low:
        return "Low";
  
      default: 
        return "";
    }
  }
  
  export const renderPriorityClass = (priority: TaskPriority) => {
    switch (priority) {
      case TaskPriority.High:
        return "badge-high";
      case TaskPriority.Medium:
        return "badge-medium";
      case TaskPriority.Low:
        return "badge-low";
      default: 
        return "badge-secondary"; // Default case for undefined priority
    }
  }
  
interface GroupedTasksProps {
    tasksGroupedByThread: TasksGroupedByThread;
    taskStatus: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  }
  
  export const GroupedTasks: React.FC<GroupedTasksProps> = observer(({ tasksGroupedByThread, taskStatus }) => {

    const { userStore, taskStore } = useStore();
    
    const isOverdue = (dueDate: string | undefined): boolean => {
        return dueDate ? new Date(dueDate) < new Date() : false;
      };

    const sortedThreadIds = Object.entries(tasksGroupedByThread).sort(([_, tasksA], [__, tasksB]) => {
        const earliestTaskA = tasksA[0];
        const earliestTaskB = tasksB[0];
      
        const overdueA = isOverdue(earliestTaskA.dueDate);
        const overdueB = isOverdue(earliestTaskB.dueDate);
      
        if (overdueA && !overdueB) {
          return -1;
        } else if (!overdueA && overdueB) {
          return 1;
        }
      
        const dateA = earliestTaskA.dueDate ? new Date(earliestTaskA.dueDate).getTime() : Number.MAX_VALUE;
        const dateB = earliestTaskB.dueDate ? new Date(earliestTaskB.dueDate).getTime() : Number.MAX_VALUE;
      
        if (dateA !== dateB) {
          return dateA - dateB;
        }
      
        return earliestTaskB.priority - earliestTaskA.priority;
      });
      
      const totalTasks = Object.values(tasksGroupedByThread).reduce((sum, tasks) => sum + tasks.length, 0);

      
   
  return (

    <div>
        Since {formatRelativeTime(userStore.me!.lastLogin!)}, we have evaluated {sortedThreadIds.length} threads and extracted {totalTasks} tasks. Here is a prioritized list: 
        <hr/>
      {sortedThreadIds.map(([threadId, tasks]) => (
        <div key={threadId} className="card mb-2">
            <div className="card-body">
                <h3>{tasks[0].thread.subject || `Thread: ${threadId}`}</h3>
                
                <div className='bg-light p-3 mb-4'>
                    TLDR: {tasks[0].thread.tldr}
                </div>
                <b>Tasks</b>
                <ul>
                    {tasksGroupedByThread[threadId]
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => b.priority - a.priority) // Sort by priority, highest first
                        .map(task => (
                        <li key={task.id}>
                            <input type="checkbox" className="mr-2" 
          checked={task.status === TaskStatus.Completed}
          onChange={(event) => taskStatus(event, task.id)}></input>
          
                            {!task.replyExpected && <span className="badge badge-danger mr-2">No need to reply</span>}
                            <span className="badge badge-info mr-2">Due: {formatRelativeTime(task.dueDate!)}</span> 
                            <span className={`badge ${renderPriorityClass(task.priority)} mr-2`}>{renderPriority(task.priority)}</span>
                            <span className='badge badge-light mr-4'>{TaskCategory[task.category]}</span>
                            {task.title}
                            
                        </li>
                    ))}
                </ul>
            </div>
        </div>
      ))}
    </div>
  );
  });
  