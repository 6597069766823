import React from "react";
import { MessageType, Task, TaskCategory, TaskPriority, TaskStatus } from "../models/task";
import { formatRelativeTime } from "../utils/dateUtils";
import { renderPriority, renderPriorityClass } from "./GroupedTask";
// ...other imports...

interface TodaysTasksProps {
  tasks: Task[];
  taskStatusChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
}

const isToday = (dueDate: string) => {
    const today = new Date();
    const date = new Date(dueDate);
    return date.setHours(0,0,0,0) === today.setHours(0,0,0,0);
  };
  
  const isOverdue = (dueDate: string) => {
    const today = new Date();
    const date = new Date(dueDate);
    return date < today && !isToday(dueDate);
  };
  
  const daysOverdue = (dueDate: string) => {
    const due = new Date(dueDate);
    const today = new Date();
    return Math.ceil((today.getTime() - due.getTime()) / (1000 * 3600 * 24));
  };
  
  
  export const TodaysTasks: React.FC<TodaysTasksProps> = ({ tasks, taskStatusChange }) => {
    const todaysTasks = tasks
    .filter(task => task.dueDate && isToday(task.dueDate))
    .sort((a, b) => b.priority - a.priority); // Sort today's tasks by priority

  const overdueTasks = tasks
    .filter(task => task.dueDate && isOverdue(task.dueDate))
    .sort((a, b) => {
      const priorityDifference = b.priority - a.priority;
      if (priorityDifference !== 0) {
        return priorityDifference; // Sort by priority first
      }
      return daysOverdue(a.dueDate!) - daysOverdue(b.dueDate!); // Then by how long overdue
    });
    return (
      <div>
        <b>Today's Tasks</b><br/>
        <ul>
          {todaysTasks.length === 0 && <>Nothing due today</>}  
          {todaysTasks.map(task => (
                      <li key={task.id} className="mb-4">
                      <input type="checkbox" className="mr-2" 
          checked={task.status === TaskStatus.Completed}
          onChange={(event) => taskStatusChange(event, task.id)}></input>
                      <span className="badge badge-info mr-2">{formatRelativeTime(task.dueDate!)}</span> 
                      <span className={`badge ${renderPriorityClass(task.priority)} mr-2`}>{renderPriority(task.priority)}</span>
                      <span className="badge badge-light">Type: {MessageType[task.message.type]}</span> <span className="badge badge-light">Category: {TaskCategory[task.category]}</span>
                      
                      <br/>{task.title}
                  </li>
          
          ))}
        </ul>
  
        <b>Overdue Tasks</b><br/>
        <ul>
          {overdueTasks.map(task => (
            <li key={task.id} className="mb-4">
            <input type="checkbox" className="mr-2" 
checked={task.status === TaskStatus.Completed}
onChange={(event) => taskStatusChange(event, task.id)}></input>
            <span className="badge badge-info mr-2">{formatRelativeTime(task.dueDate!)}</span> 
            <span className={`badge ${renderPriorityClass(task.priority)} mr-2`}>{renderPriority(task.priority)}</span>
            <span className="badge badge-light">Type: {MessageType[task.message.type]}</span> <span className="badge badge-light">Category: {TaskCategory[task.category]}</span> <span className="badge badge-light">Subject: {task.thread.subject}</span>
            
            <br/>{task.title}
        </li>
          ))}
        </ul>
      </div>
    );
  };
  