import { observer } from "mobx-react-lite";
import React from "react";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (until: Date | null) => void;
    mode: string | undefined;
  }

  const UpdateStatusModal: React.FC<ModalProps> = ({ isOpen, onClose, onUpdate, mode }) => {
   
    const handleOptionSelect = (option: 'restOfDay' | 'tomorrow' | 'nextWeek' |'nextHour' | 'custom' | null) => {
        let untilLocal: Date = new Date(); // This is in local time
        let untilUTC: Date;
      
        switch (option) {
          case 'nextHour':
            untilLocal = new Date(untilLocal.getTime() + 60 * 60 * 1000); // Adds 60 minutes (1 hour) to the current time
            break;
          case 'restOfDay':
            untilLocal.setHours(18, 0, 0, 0); // 6pm local time
            break;
          case 'tomorrow':
            untilLocal.setDate(untilLocal.getDate() + 1);
            untilLocal.setHours(8, 0, 0, 0); // 8am local time the next day
            break;
          case 'nextWeek':
            let daysUntilNextMonday = (7 - untilLocal.getDay()) % 7 + 1;
            untilLocal.setDate(untilLocal.getDate() + daysUntilNextMonday);
            untilLocal.setHours(8, 0, 0, 0); // 8am local time on the next Monday
            break;
          case 'custom':
            const customDateStr = window.prompt('Enter the date and time as YYYY-MM-DD HH:MM', '2024-01-01 12:00');
            if (customDateStr) {
              const customDate = new Date(customDateStr);
              if (!isNaN(customDate.getTime())) {
                untilLocal = customDate;
              } else {
                alert('Invalid date, please use the format YYYY-MM-DD HH:MM');
                return;
              }
            } else {
              return; // Exit if prompt is cancelled or input is empty
            }
            break;
        }
      
        // Convert local time to UTC
        untilUTC = new Date(untilLocal.getTime() - (untilLocal.getTimezoneOffset() * 60000));
      
        onUpdate(option ? untilUTC : null); // Pass the UTC date/time back
        onClose();
      };
      
  
    if (!isOpen) return null;
  
    return (
      <div className="modal show" tabIndex={-1} style={{ display: "block" }} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{mode}</h5>
              
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <p>How long do you want to {mode} to be running?</p>
              <button className="btn btn-primary mb-2" onClick={() => handleOptionSelect(null)}>Until stopped manually</button><br/>
              <button className="btn btn-secondary mb-2" onClick={() => handleOptionSelect('nextHour')}>For the next hour</button><br/>
              <button className="btn btn-secondary mb-2" onClick={() => handleOptionSelect('restOfDay')}>Rest of the Day</button><br/>
              <button className="btn btn-secondary mb-2" onClick={() => handleOptionSelect('tomorrow')}>Tomorrow</button><br/>
              <button className="btn btn-secondary mb-2" onClick={() => handleOptionSelect('nextWeek')}>Next Week</button><br/>
              <button className="btn btn-secondary" disabled onClick={() => handleOptionSelect('custom')}>Custom...</button>
            </div>
          </div>
        </div>
        <div className="modal-backdrop show"></div>
      </div>
    );
  };
  

export default observer(UpdateStatusModal);