import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider,  } from "@auth0/auth0-react";
import history from "./utils/history";  
import { getConfig } from "./config";
import { StoreContext, store } from "./stores/store";
import CustomRouter from "./CustomRouter";


const onRedirectCallback = (appState : any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  useRefreshToken: true,
  scope: "openid profile email offline_access",
  onRedirectCallback,
  skipRedirectCallback: window.location.pathname === '/callback',
  authorizationParams: {
    redirect_uri: window.location.origin ,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = createRoot(document.getElementById('root')!);
root.render(
  <Auth0Provider
    {...providerConfig}
  >
    <StoreContext.Provider value={store}>
      <CustomRouter history={history}>
        <App />
      </CustomRouter>
    </StoreContext.Provider>
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
