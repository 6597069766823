import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { Task, TaskSolutionSuggestion, TaskStatus } from "../models/task";
import { Thread } from "../models/thread";

export default class TaskStore {
    tasks: Task[] = [];
    sinceLast: Task[] = [];
    loadingInitial = false;
    loadingSuggestion = false;
    activeTaskId: string | null = null;
    activeSuggestion: TaskSolutionSuggestion | null = null;
    redirThread: Thread | null = null;

    loadTasks = async (sinceLast: boolean = false) => {
        this.setLoadingInitial(true);
        try 
        {
            if(sinceLast) {
                this.tasks = await agent.Tasks.listSinceLast();
            } else {
                this.tasks = await agent.Tasks.list();
            }
            this.setLoadingInitial(false);

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }



    getThreadFromThreadId = async (externalThreadId: string) => {
        this.setLoadingInitial(true);
        try {
            this.redirThread = await agent.Threads.getByExternalId(externalThreadId);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    deleteTask = async (task: Task) => {
        await agent.Tasks.delete(task.id);
    }

    getActiveTask = () => {
        if(this.activeTaskId) {
            var task = this.tasks.find((i) => i.id == this.activeTaskId);
            return task ? task : null;
        }

        return null;
    }

    selectTask = (task: Task) => {
        if(this.activeTaskId && this.activeTaskId == task.id) {
            this.activeTaskId = null;
            this.activeSuggestion = null;

        } else {
            this.activeTaskId = task.id;
        }
    }

    reassignTask = async (task: Task, email: string) => {
        this.setLoadingInitial(true);
        try {
            await agent.Tasks.reassign(task.id, email);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoadingSuggestion = (state: boolean) => {
        this.loadingSuggestion = state;
    }

    solve = async(id: string, query: string ) => {
        await agent.Tasks.solve(id, query);
    }
    
    solveSuggestion = async(id: string, query: string) => {
        
        this.setLoadingSuggestion(true);
        
       // todo: TRY CATCH
        this.activeSuggestion = await agent.Tasks.solveSuggestion(id, query);
        this.setLoadingSuggestion(false);

    }
    
    setStatus = async (id: string, status: TaskStatus, body: string) => {
        // Find the task and remove it upfront, but save it in memory first
        const taskIndex = this.tasks.findIndex((c) => c.id === id);
        var removedTask: Task | null = null; // Placeholder for the task to be removed
    
        if (taskIndex > -1) {
            runInAction(() => {
                // Save the removed task in memory
                removedTask = this.tasks[taskIndex];
                // Remove the task from the array
                this.tasks.splice(taskIndex, 1);
            });
        }
    
        try {
            // Attempt to update the status via API
            await agent.Tasks.setStatus(id, status, body);
            // If successful, the task is already removed, and we don't need to do anything further.
        } catch (error) {
            // Handle API call failure
            if (removedTask !== null) {
                runInAction(() => {
                    // Re-add the removed task to the array at its original position
                    this.tasks.splice(taskIndex, 0, removedTask!);
                });
            }
            // Optionally, show an error message or handle the error in another way
        }
    }
    

    updateTitle = async (id: string, title: string) => {
        this.tasks.find((c) => c.id == id)!.title = title;
        await agent.Tasks.updateTitle(id, title);
    }

    constructor() {
        makeAutoObservable(this);
    }
}