import React from "react";
import { Container, Row, Col, Input } from "reactstrap";
import Loading from "../components/Loading";
import { store, useStore } from "../stores/store";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";


export const PluginsComponent = () => {
  const { user } = useAuth0();
  const { userStore } = useStore();


  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
    
        <Col md>
          <h2>Chrome plugins</h2>
          1. Download <a href="assets/dist.zip">this</a><br/>
          2. Navigate to chrome://extensions<br/>
          3. Load unpacked and point to the unpacked zip folder downloaded in step 1 <br/>
          4. Now you are able to watch thread's to-dos inside Gmail
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(observer(PluginsComponent), {
  onRedirecting: () => <Loading />,
});
