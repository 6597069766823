import React, { useEffect } from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import { UserCompanyRole } from "../models/userCompany";
import { formatRelativeTime } from "../utils/dateUtils";


const CompanyUsersComponent = () => {
  const  { companyStore } = useStore();

  useEffect(() => {
    const fetch = async () => {
      try {
        await companyStore.getUsers();
      
        console.log("load")
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetch(); 

  }, []);

  // React component for Slack authentication



  if(companyStore.loadingInitial)
   return <>loading....</>

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
    
        <Col md>
          <h2>Users</h2>
          <div className="container">
            <div className="row">
              {companyStore.users.map((userCompany) => {
                return (
                  <div className="col-sm" key={userCompany.user.id}>
                    {userCompany.user.name} - {UserCompanyRole[userCompany.role]} {userCompany.currentWorkflowMode && <>- <span className='badge badge-secondary'>In '{userCompany.currentWorkflowMode.label} {userCompany.currentWorkflowMode.name}' mode {userCompany.currentWorkflowMode.until && <>until {formatRelativeTime(userCompany.currentWorkflowMode.until!)}</>}</span></>}
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(observer(CompanyUsersComponent), {
  onRedirecting: () => <Loading />,
});
