import { Message } from "./task";
import { User } from "./user";

export enum TriageRequestType {
    Pending,
    Completed
}

export interface TriageRequest {
    // Type of email
    type: TriageRequestType,
    subject: string
    user: User
}
