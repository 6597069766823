import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { TriggerCondition, TriggerProperty, Workflow, WorkflowAction, WorkflowActionObject, WorkflowStatus, WorkflowTriggerObject, WorkflowType } from "../models/workflow";
import { WorkflowLog, WorkflowMessageLog } from "../models/workflowLog";
import { WorkflowMode } from "../models/workflowMode";

export default class WorkflowStore {
    
    loadingInitial: boolean = false;
    flows: Workflow[] = [];
    modes: WorkflowMode[] = [];
    logs: WorkflowLog[] = [];
    suggestions: Workflow[] | null = null;
    logMessages: WorkflowMessageLog[] = [];

    parseFlow = async (workflow: Workflow) => {
        return await agent.Workflows.parse(JSON.stringify(workflow));
    }
    
    createMode = async (name: string, label: string) => {
        await agent.Workflows.createMode(name, label);
    }

    changeStatus = async(id: string, status: WorkflowStatus, until: Date | null) => {
        try {
            await agent.Workflows.changeStatus(id, status, until);
            runInAction(() => {
                var elm = this.flows.find((c) => c.id == id);
                if(elm) {
                    elm.status = status;
                    elm.until = until?.toISOString();
                }
            })
        } catch(error) {
            
        }
    }

    // createStandardRules = async () => {

    //     var type = WorkflowType.Email;
        
    //     var triggers = [] as WorkflowTriggerObject[];
    //     var actions = [] as WorkflowActionObject[];

    //     actions.push({action: WorkflowAction.AddLabel, input: "{email.type}" });

    //     this.setLoadingInitial(true);
    //     await agent.Workflows.createOrUpdate(type, triggers, actions, "Standard rule");
    //     this.setLoadingInitial(false);
    // }

    suggestFlowTemp = async (input: string) => {
        this.suggestions = [];
    }

    suggestFlow = async (input: string) => {
        this.setLoadingInitial(true);
        try {
            console.log("1", this.loadingInitial, this.suggestions);
            this.suggestions = await agent.Workflows.suggest(input);

             this.setLoadingInitial(false);
             console.log("2", this.loadingInitial);
         } catch(error) {
             this.setLoadingInitial(false);
         }
         
         console.log("3", this.loadingInitial);
    }

    createOrUpdate = async (
        modeId: string | null,
        type: WorkflowType,
        triggers: WorkflowTriggerObject[],
        actions: WorkflowActionObject[],
        name: string
        ) => {
            this.setLoadingInitial(true);
            await agent.Workflows.createOrUpdate(modeId, type, triggers, actions, name);
            this.setLoadingInitial(false);
    }

    deleteFlow = async (id: string) => {
        await agent.Workflows.delete(id);
        runInAction(() => {
            this.flows = this.flows.filter(flow => flow.id !== id);
        });
    }

    loadModes = async () => {
        this.setLoadingInitial(true);
        try {
           this.modes = await agent.Workflows.listModes();
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }   
    }

    loadFlows = async () => {
        this.setLoadingInitial(true);
        try {
           this.flows = await agent.Workflows.list();
            console.log(this.flows.length);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    loadLogs = async () => {
        this.setLoadingInitial(true);
        try {
           this.logs = await agent.Workflows.logs();
            console.log(this.flows.length);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    loadLogMessages = async (action: WorkflowAction) => {
        this.setLoadingInitial(true);
        try {
           this.logMessages = await agent.Workflows.logMessages(action);
            console.log(this.flows.length);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }


    constructor() {
        makeAutoObservable(this);
    }
}