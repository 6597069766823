import React, { useState, useEffect } from 'react';

type AlertModalProps = {
  message: string;
  show: boolean;
  duration?: number; // Duration in milliseconds
  onClose: () => void; // Callback function to be called when the modal closes
};

const AlertModal: React.FC<AlertModalProps> = ({ message, show, duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (show) {
      setIsVisible(true);
      timer = setTimeout(() => {
        setIsVisible(false);
        onClose(); // Call the onClose callback when the modal is supposed to close
      }, duration);
    } else {
      setIsVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [show, duration, onClose]);

  return (
    <div className={`custom-alert alert alert-warning ${isVisible ? 'show' : ''}`} role="alert">
      {message}
    </div>
  );
};

export default AlertModal;
