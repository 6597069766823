// src/views/Login.js
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from './stores/store';
import { observer } from 'mobx-react-lite';

const Triage = () => { 

  const { triageRequestId, result } = useParams<{ triageRequestId?: string, result?: string }>();
  
  const { taskStore, triageStore } = useStore();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(result != "success") {
          // Replace with your API call
          await triageStore.getRequest(triageRequestId!);
        }
      } catch (error) {
        console.error('Error during redirection', error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, [triageRequestId, navigate, triageStore]);

  const respond = async (response: string) => {
    await triageStore.triageMessage(triageRequestId!, result!);

    navigate(`/triage/${triageRequestId}/success`);
  }

  if(triageStore.loadingInitial) return <>loading</>


  if(triageStore.request == null) return <>not found</>

  return <div>
    
    {result == "success" ? <>Okay, we have notified about it</> : <>

      <b>Sure you want to disturb?</b><br/>
      You are about to nudge {triageStore.request.user.name} about <b>{triageStore.request.subject}</b>.

      <br/><br/>
      
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-danger" onClick={() => respond("yes")}>Yes, it is important</button>
        <button type="button" className="btn btn-secondary" onClick={() => respond("no")}>No, nevermind</button>
      </div>
    
    </>}
  </div>; 
};

export default observer(Triage);
