import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Integration } from "../models/integration";

export default class IntegrationStore {
    
    loadingInitial = false;
    integrations: Integration[] | null = null;
    setupData = new Map<string, any>() ;
    resyncRequired = new Map<string, any>() ;


    delete = async (id: string) => {
        this.setLoadingInitial(true);
        try 
        {
           await agent.Integrations.delete(id);
            this.setLoadingInitial(false);

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }

    add = async (id: string, code: string) => {
        this.setLoadingInitial(true);
        try 
        {
           await agent.Integrations.add(id, code);
            this.setLoadingInitial(false);

        } catch (error) { 
            console.log(error); 
            this.setLoadingInitial(false);
        }
    }

    loadSetupData = async (id: string) => {
        this.setLoadingInitial(true);
        try {
            const data = await agent.Integrations.getConfigData(id);
            this.setupData.set(id, data);
            this.resyncRequired.delete(id);
            this.setLoadingInitial(false);
         } catch(error) {
            console.log('aa', error);
            this.resyncRequired.set(id, "");
            this.setLoadingInitial(false);
         }
    }

    loadIntegrations = async () => {
        this.setLoadingInitial(true);
        try {
           this.integrations = await agent.Integrations.list();
            console.log(this.integrations.length);
            this.setLoadingInitial(false);
        } catch(error) {
            this.setLoadingInitial(false);
        }
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    constructor() {
        makeAutoObservable(this);
    }
}