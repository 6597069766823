import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { Task, TaskStatus } from "../models/task";
import { Thread } from "../models/thread";

export default class ThreadStore {
    
    thread: Thread | null = null;


    getThread = async(threadId: string | null) => {
        if(threadId) {
            this.thread = await agent.Threads.get(threadId);
        } else {
            this.thread = null;
        }
    }
    
    // createDraft = async (threadId: string) => {
    //     await agent.Threads.createDraft(threadId);
    // }

    constructor() {
        makeAutoObservable(this);
    }
}