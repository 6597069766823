import React, { useState, useEffect } from 'react';

interface RatingDotsProps {
  readOnly: boolean;
  selected: number; // Used for initial state and when readOnly is true
}

const RatingDots: React.FC<RatingDotsProps> = ({ readOnly, selected }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);
  const [clickedIndex, setClickedIndex] = useState<number>(selected);

  useEffect(() => {
    // Reset clickedIndex to selected when selected prop changes, useful for external updates
    if (readOnly) {
      setClickedIndex(selected);
    }
  }, [selected, readOnly]);

  const handleMouseEnter = (index: number) => {
    if (!readOnly) {
      setHoveredIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!readOnly) {
      // Revert to displaying the clickedIndex (or selected) state on mouse leave
      setHoveredIndex(0);
    }
  };

  const handleClick = (index: number) => {
    if (!readOnly) {
      setClickedIndex(index);
      // Optionally, trigger an action to externalize the click selection
      console.log(`Selected value: ${index}`);
    }
  };

  const renderDots = () => {
    return Array.from({ length: 5 }, (_, index) => {
      const idx = index + 1;
      // Determine if a dot should be marked as selected based on hover or click
      const isSelected = idx <= (hoveredIndex > 0 ? hoveredIndex : clickedIndex);
      return (
        <div
          key={idx}
          className={`dot ${isSelected ? 'selected' : ''}`}
          onMouseEnter={() => handleMouseEnter(idx)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(idx)}
        />
      );
    });
  };

  return <div className="dots-container">{renderDots()}</div>;
};

export default RatingDots;
