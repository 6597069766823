import React, { useState, useEffect } from 'react';
import { WorkflowActionObject } from '../../../models/workflow';
import { useStore } from '../../../stores/store';

interface TodoistWidgetProps {
  action: WorkflowActionObject;
  updateAction: (indexPath: number[], field: keyof WorkflowActionObject, value: any) => void;
  indexPath: number[];
}

const TodoistWidget: React.FC<TodoistWidgetProps> = ({ action, updateAction, indexPath }) => {
  

  const  { integrationStore} = useStore();


  // Parse the input if it's a JSON string; otherwise, initialize with empty values
  const initialInput = action.input ? JSON.parse(action.input) : { projectId: '', message: '' };
  const [projectId, setProjectId] = useState(initialInput.projectId);
  const [message, setMessage] = useState(initialInput.message);

  useEffect(() => {
    // Mock API call
    const fetchDropdownOptions = async () => {
        await integrationStore.loadSetupData("todoist");
    };

    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    // Update the parent component whenever the inputs change
    const jsonValue = JSON.stringify({ projectId, message });
    updateAction(indexPath, 'input', jsonValue);
  }, [projectId, message, indexPath, updateAction]);

  if(integrationStore.resyncRequired && integrationStore.resyncRequired.has("todoist"))
    return <>Connect again... Something is not right</>

  return (
    <>
      <select 
        className='form-control mb-2'
        value={projectId} 
        onChange={(e) => setProjectId(e.target.value)}
        disabled={integrationStore.loadingInitial}
      >
        <option value="">Select a task</option>
        {integrationStore.setupData.get("todoist") && integrationStore.setupData.get("todoist").projects.map((option: any) => (
          <option key={option.id} value={option.id}>{option.name}</option>
        ))}
      </select>
      <input 
        className='form-control'
        placeholder='{task}'
        type="text" 
        value={message} 
        onChange={(e) => setMessage(e.target.value)} 
        disabled={integrationStore.loadingInitial}
      />
    </>
  );
};

export default TodoistWidget;
