import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import { WorkflowAction } from "../models/workflow";
import { formatRelativeTime } from "../utils/dateUtils";

import { useNavigate } from "react-router-dom";




const LogsComponent = () => {
  const  { logStore, scoreStore, userStore, workflowStore } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      try {
        await logStore.loadLogs();
        await workflowStore.loadLogs();
      } catch (error) {
        console.error('Failed to load tasks:', error);
      }
    };

    fetch(); 

  }, []);


  // React component for Slack authentication
  const renderIcon = (eventType: string) => {
    switch (eventType) {
      case 'Email':
        return <>📧</>;
      case 'WorkflowMode':
        return <>:</>;
      case 'WorkflowAction':
          return <>✨</>;
      default:
        return null;
    }
  };


  const WorkflowActivity = ({ details, id ,created}: any) => {
    const group = groupedActivities[id];
  
    
    // Render the workflow activity details
    return (
      <div className="ml-4">
        {/* Render your workflow activity details here */}
        ⚡ Action executed <span className="badge badge-secondary">{WorkflowAction[details.action]}</span>
        <small>{formatRelativeTime(created)}</small>
        {group.integrations
        .filter((x: any) => x.details.messageId == details.messageId && x.details.workflowActionId == details.workflowActionId)
        .sort((a: Log, b: Log) => new Date(a.created).getTime() - new Date(b.created).getTime())
        .map(activity =>  
          <div><IntegrationActivity created={activity.created} key={activity.id} details={activity.details as IIntegrationDetails} /></div>
        )}
       
      </div>
    );
  };
  
  const groupActivitiesByThread = (activities: Log[]) => {
    const grouped: { [key: string]: { emails: Log[], workflowActions: Log[], integrations: Log[], systemWorkflow: Log[] , systemWorkflowMode: Log[] } } = {};
  
    activities.forEach(activity => {
      const threadId = (activity.details as any).threadId; // Type assertion for threadId
  
      if (!grouped[threadId]) {
        grouped[threadId] = {
          emails: [],
          workflowActions: [],
          integrations: [],
          systemWorkflow: [],
          systemWorkflowMode: [],
        };
      }
  
      switch (activity.eventType) {
        case 'Email':
          grouped[threadId].emails.push(activity);
          break;
        case 'WorkflowAction':
          grouped[threadId].workflowActions.push(activity);
          break;
        case 'Integration':
          grouped[threadId].integrations.push(activity);
          break;
        case 'SystemWorkflow':
          grouped[threadId].systemWorkflow.push(activity);
          break;
        case 'SystemWorkflowMode':
          grouped[threadId].systemWorkflowMode.push(activity);
          break;
        default:
          break;
      }
    });
  
    return grouped;
  };

  const renderGroupedActivities = (groupedActivities: { [key: string]: { emails: Log[], workflowActions: Log[], integrations: Log[], systemWorkflow: Log[], systemWorkflowMode: Log[] } }) => {
    return Object.keys(groupedActivities)
    .map(messageId => {
      const group = groupedActivities[messageId];
  
      var t = group.emails.slice()
      .concat(group.systemWorkflow.slice())
      .concat(group.systemWorkflowMode.slice());

      return (
        <div key={messageId}>
          {t .sort((a: any,b: any) => new Date(b.created).getTime() - new Date(a.created).getTime()).map((activity,i) => 
            <>
              {(activity.eventType == "Email" && <EmailActivity key={activity.id} id={activity.messageId} created={activity.created} details={activity.details as IEmailDetails} />)}
              {(activity.eventType == "SystemWorkflow" && <SystemWorkflow key={i} created={activity.created} details={activity.details as ISystemWorkflowDetails} />)}
              {(activity.eventType == "SystemWorkflowMode" && <SystemWorkflowMode key={i} created={activity.created} details={activity.details as ISystemWorkflowModeDetails} />)}
              
            </>
          )}
        </div>
      );
    });
  };
  

  const SystemWorkflowMode = ({ details,  created } : any) => {

    // Render the email activity details
    return (
      <div className="card mt-2">
        {/* Render your email activity details here */}
        <div className="card-box p-4">
          Focus mode set to '{details.workflowModeId}' <span className={`badge badge-${details.action === "Paused" ? "danger" : "success"}`}>{details.action}</span> <small>{formatRelativeTime(created)}</small>
        </div>
          
      </div>
    );
  };


  const SystemWorkflow = ({ details,  created } : any) => {

    // Render the email activity details
    return (
      <div className="card mt-2">
        {/* Render your email activity details here */}
        <div className="card-box p-4">
          Worfklow '{details.workflowId}' changed <span className={`badge badge-${details.action === "Paused" ? "danger" : "success"}`}>{details.action}</span> <small>{formatRelativeTime(created)}</small>
        </div>
          
      </div>
    );
  };

  const EmailActivity = ({ details, id, created } : any) => {

    const group = groupedActivities[id];
    
    // Render the email activity details
    return (
      <div className="card mt-2">
        {/* Render your email activity details here */}
        <div className="card-box p-4">
          📧 New email <span className='badge badge-info'>{details.subject}</span> recieved <span className="badge badge-light">{details.receiver}</span> from <span className="badge badge-light">{details.sender}</span><small>{formatRelativeTime(created)}</small>
          {group.workflowActions.filter((x: any) => x.details.messageId == details.messageId).map(activity => <><WorkflowActivity created={activity.created} id={activity.messageId} key={activity.id} details={activity.details as IWorkflowDetails} /></>)}
        </div>
          
      </div>
    );
  };

  const move = (url: string, date: string) => {
    navigate(`/${userStore.me?.lastCompanyId}/scores/${url}/${date}`);
  }

  const IntegrationActivity = ({ details, created } : any) => {
    // Render the email activity details
    return (
      <div className="ml-4">
        <div className="ml-4">
          {/* Render your email activity details here */}
          
          🤝 <span className="badge badge-success">{details.response}</span>
          <small>{formatRelativeTime(created)}</small>
        </div>
      </div>
    );
  };
  // Assuming scoreStore.scores is your GroupedScore array
  const { scores } = scoreStore;

  const clickLog = async(action: WorkflowAction) => {
    await workflowStore.loadLogMessages(action);
  }

  if(logStore.logs == null)
   return <>loading....</>

   const groupedActivities = groupActivitiesByThread(logStore.logs || []);



   return (
     <Container className="mb-5">
       <Row className="align-items-center profile-header mb-5 text-center text-md-left">
         <Col md>
           <h1 className="mb-4">Activity</h1>

            <h4>Automations performed</h4>
           <div className='card-deck mb-4 mt-4'>
        {workflowStore.logs.map((log, i) => {
            return <div className='card' key={i} onClick={() => clickLog(log.action)}>
              <div className='card-body'>
                <h2>{log.count}</h2>
                {WorkflowAction[log.action]} executed
              </div>
            </div>
          })}</div>


          {workflowStore.logMessages.length > 0 && (
            
            <div>
              {workflowStore.logMessages.map((logMessage, index) => (
                <div key={index}>
                  {/* Render your log message here */}
                  {logMessage.messageId}
                </div>
              ))}
            </div>
          )}

<h4>Events</h4>
           {logStore.logs && logStore.logs.length == 0 && <>No activity yet...</>}
           {renderGroupedActivities(groupedActivities)}
         </Col>
       </Row>
     </Container>
   );
};

export default withAuthenticationRequired(observer(LogsComponent), {
  onRedirecting: () => <Loading />,
});


